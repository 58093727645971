import { convertColumnIndexToCharacter } from '../../../common/utilities/excelUtilities';

const getRangeToReplace = async context => {
  const {
    shortDescription,
    shortDescriptionColumnIndex,
    longDescriptionColumnIndex,
    rowIdColumnIndex,
    columnOffset,
    excelContext,
    usedRange
  } = context;

  // This is done since we need the values of the usedRange to be populate.
  // This command makes excel execute all queued requests for data
  await excelContext.sync().catch(() => {
    throw new Error('Error Getting Range to Replace');
  });

  const startingRow = Number(
    usedRange.address
      .split('!')
      .pop()
      .split(':')[0]
      .match('([0-9]+)')[1]
  );

  const matchingRows = usedRange.values
    .map((row, index) => ({
      rowNumber: index + startingRow,
      row
    }))
    .filter(({ row }) => {
      return (
        row[shortDescriptionColumnIndex].toLowerCase() ===
        shortDescription.toLowerCase()
      );
    })
    .map(({ rowNumber }) => [
      `${convertColumnIndexToCharacter(
        rowIdColumnIndex,
        columnOffset
      )}${rowNumber}`,
      `${convertColumnIndexToCharacter(
        longDescriptionColumnIndex,
        columnOffset
      )}${rowNumber}`
    ]);

  return Promise.resolve({
    ...context,
    rangeToReplace: matchingRows
  });
};

export default getRangeToReplace;
