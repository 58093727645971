import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ClipboardHttpClient } from '../services/apiClients';
import { getProjectData } from '../../features/project/project';
import { getFullPath } from '../../features/workbook/workbook';
import { featureFlags } from '../helpers/featureFlag';

export const useFileSize = () => {
  const fullPath = useSelector(getFullPath);
  const { id: projectId } = useSelector(getProjectData) || {};
  const [state, setState] = useState({
    isLoading: true,
    hasError: false,
    fileSizeInBytes: null,
    hasCloseError: false
  });

  useEffect(() => {
    const fetchFileSize = async () => {
      if (!projectId || !featureFlags.showFileSizeWarning) return;
      try {
        const { data } = await ClipboardHttpClient.post({
          relativePath: `/projects/${projectId}/driveDetails`,
          data: {
            sharePointItemUrl: fullPath
          }
        });
        setState({
          isLoading: false,
          hasError: false,
          fileSizeInBytes: data.data.fileSize
        });
      } catch (error) {
        setState({
          isLoading: false,
          hasError: true,
          fileSizeInBytes: null
        });
      }
    };
    fetchFileSize();
  }, [fullPath, projectId]);

  if (!featureFlags.showFileSizeWarning) {
    return {
      isLoading: false,
      hasError: false,
      fileSizeInBytes: -1
    };
  }

  return state;
};
