import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setWorkbookThunk } from '../../../features/workbook/workbook';

export default () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setWorkbookThunk(window.Office.context.document));
  }, [dispatch]);

  return '';
};
