import { isEmpty, groupBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export const buildInputForSave = ({
  documentId,
  documentCode,
  projectId,
  localWorkSheet,
  excelContext,
  columnOffset,
  configurationValues,
  usedRange
}) => row => ({
  documentId,
  libraryItemId: '',
  longDescription: `<p>${row.longDescription}</p>`,
  documentCode,
  shortDescription: row.shortDescription,
  rowId: uuidv4(),
  projectId,
  worksheet: localWorkSheet,
  excelContext,
  columnOffset,
  shortDescriptionColumnIndex:
    configurationValues.adjustmentShortDescColumnIndex,
  longDescriptionColumnIndex: configurationValues.adjustmentLongDescColumnIndex,
  rowIdColumnIndex: configurationValues.adjustmentRowIdColumnIndex,
  registeredLinks: [],
  adjustmentNumber: row.adjustmentNumber,
  rowNumber: row.rowNumber,
  usedRange
});

const buildRowObjectFromExcelData = (
  configuration,
  excelData,
  adjustedRowNumber
) => {
  return {
    adjustmentNumber: excelData[configuration.adjustmentNumberColumnIndex],
    shortDescription: excelData[configuration.adjustmentShortDescColumnIndex],
    longDescription: excelData[configuration.adjustmentLongDescColumnIndex],
    rowId: excelData[configuration.adjustmentRowIdColumnIndex],
    rowNumber: adjustedRowNumber
  };
};

export const alreadyCapturedFilter = row =>
  !row.longDescription.startsWith('Content available to');

export const transformExcelDataIntoObject = (
  firstRowOfDataIndex,
  configuration,
  usedRangeValues
) =>
  usedRangeValues
    .slice(firstRowOfDataIndex)
    .map((r, index) =>
      buildRowObjectFromExcelData(
        configuration,
        r,
        firstRowOfDataIndex + (index + 1)
      )
    );

export const buildRowErrorMessage = (rowNumber, errorMessage) => {
  return `Row ${rowNumber} ${errorMessage}`;
};

export const validateBulkRow = row => {
  if (!isEmpty(row.rowId)) {
    return buildRowErrorMessage(
      row.rowNumber,
      'Row Id is populated, but should be empty'
    );
  }

  if (isEmpty(row.shortDescription)) {
    return buildRowErrorMessage(row.rowNumber, 'Short Description is missing');
  }

  if (isEmpty(row.longDescription)) {
    return buildRowErrorMessage(row.rowNumber, 'Long Description is missing');
  }

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(parseInt(row.adjustmentNumber, 10))) {
    return buildRowErrorMessage(row.rowNumber, 'Adjustment Number is missing');
  }

  return undefined;
};

export const validateAllBulkRows = (rows = []) =>
  rows.map(r => ({
    row: r,
    error: validateBulkRow(r)
  }));

export const errorToDiv = error =>
  `<div class='status-message-item'>${error}</div>`;

export const errorsArrayToDivString = errors =>
  errors.reduce((acc, cur) => acc + errorToDiv(cur), '');

export const getRowsForSaveAndValidationErrors = (
  firstRowOfDataIndex,
  configurationValues,
  excelValues
) => {
  const excelAsRowObjects = transformExcelDataIntoObject(
    firstRowOfDataIndex,
    configurationValues,
    excelValues
  );
  const rowsNotCaptured = excelAsRowObjects.filter(alreadyCapturedFilter);
  const rowsWithValidationResults = validateAllBulkRows(rowsNotCaptured);

  const adjustmentsGrouped = groupBy(
    rowsWithValidationResults,
    x => `${x.row.adjustmentNumber}__||__${x.row.shortDescription}`
  );

  const firstOnlyWithoutErrors = Object.keys(adjustmentsGrouped).reduce(
    (acc, key) => {
      const rowsForKey = adjustmentsGrouped[key];
      const hasErrors =
        rowsForKey.filter(x => x.error !== undefined).length > 0;
      if (!hasErrors) {
        acc.push(rowsForKey[0]);
      }
      return acc;
    },
    []
  );

  const rowsForSave = firstOnlyWithoutErrors
    .filter(r => r.error === undefined)
    .map(x => x.row);

  const validationErrors = rowsWithValidationResults // always the full list, no grouping of adj num + short desc
    .filter(r => r.error !== undefined)
    .map(x => x.error);

  return {
    rowsForSave,
    validationErrors
  };
};
