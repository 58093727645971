import { ProjectApiClient } from '@pwc/project-api-client';
import {
  ProjectHttpClient,
  StructuredAnalysisHttpClient,
  LibraryItemXlIframeClient
} from './apiClients';

const projectApiClient = new ProjectApiClient(ProjectHttpClient);

export const getLibraryItemData = (projectId, libraryItemId) => {
  return projectApiClient.getLibraryItemData({ projectId, libraryItemId });
};

export const postChart = (projectId, chartData) =>
  projectApiClient.postChart({ projectId, chartData });

export const postNamedRange = (projectId, namedRangeData) =>
  projectApiClient.postNamedRange({ projectId, namedRangeData });

export const getLibraryItemDataForExcelAddin = async (
  projectId,
  libraryItemId
) => {
  const relativePath = `/projects/${projectId}/LibraryItems/forExcelAddin/${libraryItemId}`;
  const response = await StructuredAnalysisHttpClient.get({
    relativePath
  });
  return response.data;
};

/**
 * data: {

    "WorksheetName": "Sheet1",
    "Name": "Sheet1!A1:D7",
    "UniqueId": "{00000000-0001-0000-0000-000000000000}",
    "SiteId": "devpwc365.sharepoint.com,beb96818-301f-4179-b3e4-1a4523b1199b,fdfe24e4-266e-43a6-aa0a-ae6ae5020c43",
    "DriveId": "b!GGi5vh8weUGz5BpFI7EZm-Qk_v1uJqZDqgquauUCDEOrh73fRhIRRJaiCv3IlY5Q",
    "DriveItemId": "01CAQQ6AA5XMSSJ4NI3FCLXC5LPNH3RBIO",
    "SharePointItemUrl": "https://devpwc365.sharepoint.com/sites/usassurancedf5c6db8637d4bb0aa0ad4767edb6bd7/Shared Documents/Financial Sample.xlsx",
    "EmbeddedUrl": ""
}
 */
export const ingestDynamicContent = async (projectId, data) => {
  return LibraryItemXlIframeClient.post({
    relativePath: `xliframeservice/${projectId}/cellRange`,
    data
  });
};
