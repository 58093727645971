import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getFullPath } from '../workbook/workbook';
import Navigation from '../../common/components/layout/Navigation';
import { getProjectData, setProjectThunk } from '../project/project';
import ExcelProject from '../../common/components/layout/ExcelProject';
import Excel from '../../common/components/Excel/Excel';
import { useExcelContext } from '../../common/components/Excel/useExcelContext';
import ChartPickerContainer from './ChartPickerContainer';
import NamedRangePickerContainer from './NamedRangePickerContainer';

const ContentPickerContainer = () => {
  const excelContext = useExcelContext();
  const fullPath = useSelector(getFullPath);
  const project = useSelector(getProjectData);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setProjectThunk(fullPath));
  }, [dispatch, fullPath]);

  return (
    <>
      <Excel />
      <Navigation />
      <ExcelProject excelContext={excelContext}>
        <ChartPickerContainer
          project={project}
          fullPath={fullPath}
          excelContext={excelContext}
        />
        <NamedRangePickerContainer
          project={project}
          fullPath={fullPath}
          excelContext={excelContext}
        />
      </ExcelProject>
    </>
  );
};

export default ContentPickerContainer;
