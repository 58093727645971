const getRangeAndSheet = context => {
  const { excelContext } = context;

  const sheet = excelContext.workbook.worksheets.getActiveWorksheet();
  sheet.load('name');
  const range = excelContext.workbook.getSelectedRange();
  range.load('address,values');

  return excelContext
    .sync()
    .catch(() => {
      throw new Error('Please select only one range');
    })
    .then(() => {
      if (range.values.length > 1) {
        throw new Error('Please select only one cell');
      }
      const address = range.address.split('!').pop();
      const [, column, row] = address.match('([A-Z])([0-9].*)');

      return {
        ...context,
        longDescription: range.values[0][0],
        row: parseInt(row, 10) - 1,
        selectedColumn: column,
        worksheet: sheet,
        worksheetName: sheet.name,
        excelContext
      };
    });
};

export default getRangeAndSheet;
