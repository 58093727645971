export const removeChartEventListeners = async excelContext => {
  const sheet = excelContext.workbook.worksheets.getActiveWorksheet();
  sheet.load('charts');
  sheet.load('name');

  await excelContext.sync();

  sheet.charts.items.forEach(item => {
    item.onActivated.removeAll();
    item.onDeactivated.removeAll();
  });

  await excelContext.sync();
};

export const addChartEventListeners = async excelContext => {
  const sheet = excelContext.workbook.worksheets.getActiveWorksheet();
  sheet.load('charts');
  sheet.load('name');
  sheet.load('shapes');

  await excelContext.sync();

  // sheet.charts.items.forEach(item => {
  //   // TODO add onAdded same callback to fix if added new chart to copy it right away
  //   item.onActivated.add(e => {
  //     const selectedChartName = sheet.charts.items.filter(x => {
  //       return x.id === e.chartId;
  //     });
  //     window.selectedChart = null;

  //     if (selectedChartName.length > 0) {
  //       window.selectedChart = JSON.stringify({
  //         chartName: selectedChartName[0].name,
  //         workSheet: sheet.name
  //       });
  //     }
  //   });
  // });

  // TODO: This code changes to ingest chart/group objects both, in future sprint this needs to refactored
  sheet.shapes.items.forEach(item => {
    // TODO add onAdded same callback to fix if added new chart to copy it right away
    item.onActivated.add(() => {
      window.selectedChart = JSON.stringify({
        chartName: item.name,
        workSheet: sheet.name,
        uniqueId: sheet.id
      });
    });
  });

  await excelContext.sync();
};

export const getExcelCellRangeOrChart = async excelContext => {
  const sheet = excelContext.workbook.worksheets.getActiveWorksheet();
  sheet.load('name');
  const range = excelContext.workbook.getSelectedRange();
  range.load('address,values');

  await excelContext.sync().catch(() => {
    throw new Error('Please select only one range');
  });

  return {
    range,
    sheet
  };
};

export const getExcelSheetName = async () => {
  return window.Excel.run(async context => {
    const sheet = context.workbook.worksheets.getActiveWorksheet();
    sheet.load('name');

    await context.sync().catch(() => {
      throw new Error('Please select only one range');
    });

    return {
      sheet
    };
  });
};

export const selectRange = async rangeSelection => {
  return window.Excel.run(async context => {
    const sheet = context.workbook.worksheets.getActiveWorksheet();
    const range = sheet.getRange(rangeSelection);

    range.select();

    await context.sync().catch(() => {
      throw new Error('Please select only one range');
    });

    return {
      sheet
    };
  });
};
