import React, { useEffect, useRef, useState } from 'react';
import { PwcCKEditors } from '@pwc/pwc-deals-rich-text-editor';
import CKEditor from '@ckeditor/ckeditor5-react';
import InsertLibraryItem from '../InsertLibraryItem/InsertLibraryItem';
import InlineMessage from '../../../common/components/InlineMessage/InlineMessage';
import initializeCKEditor from './initializeCKEditor';
import platformName from '../../../common/utilities/get.platform.name';
import ckEditorMiniTableInsert from '../services/ckEditorMiniTableInsert';
import './RichTextEditor.scss';

const { ClassicEditor } = PwcCKEditors || {};

const editorConfig = {
  removePlugins: ['RestrictedEditingMode', 'TextTransformation'],
  licenseKey: process.env.REACT_APP_CK_EDITOR_LICENSE,
  language: 'en',
  toolbar: {
    items: [
      'bold',
      'italic',
      'bulletedList',
      'numberedList',
      'underline',
      '|',
      'addMinitableChart'
    ],
    shouldNotGroupWhenFull: false,
    shouldShowMiniTableText: false
  }
};

function RichTextEditor({
  excelContext,
  onChange,
  richText,
  registeredLinks,
  setRegisteredLinks,
  editorError,
  setEditorError,
  projectId,
  sharePointSiteUrl
}) {
  const editorTarget = useRef(null);
  const [editor, setEditor] = useState(null);
  const [insertingLibraryItem, setInsertingLibraryItem] = useState(false);

  useEffect(() => {
    if (editor) {
      try {
        initializeCKEditor(editor, richText.text, richText.links);

        editorTarget.current.addEventListener(
          'inserting-minitable-chart',
          () => {
            ckEditorMiniTableInsert(projectId, editor, sharePointSiteUrl);
          }
        );

        const linkIds = richText.links.map(link => link.id);
        setRegisteredLinks(linkIds);

        editorTarget.current.addEventListener('inserting-library-item', () => {
          setInsertingLibraryItem(true);
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error initializing CKEditor:', e);
        setEditorError(true);
      }
    }
  }, [richText, editor, setRegisteredLinks, setEditorError]);

  function onSelectedLibraryItem(libraryItem) {
    editor.execute('addLibraryItem', libraryItem);
    setRegisteredLinks([...registeredLinks, libraryItem.id]);
    setInsertingLibraryItem(false);
  }

  return (
    <div ref={editorTarget} id="rich-text-editor">
      {!editorError && (
        <CKEditor
          editor={ClassicEditor}
          onInit={ckeditor => {
            setEditor(ckeditor);
          }}
          onChange={(_, ckeditor) => onChange(ckeditor.getData())}
          config={editorConfig}
        />
      )}
      {editorError && (
        <InlineMessage
          text={`Error showing content from ${platformName}`}
          type="failure"
        />
      )}
      {insertingLibraryItem && (
        <InsertLibraryItem
          onCancel={() => setInsertingLibraryItem(false)}
          onSave={() => onSelectedLibraryItem()}
          excelContext={excelContext}
        />
      )}
    </div>
  );
}

export default RichTextEditor;
