/* eslint-disable react/no-danger */
import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MiniLoader, Button, Icon, Tooltip } from '@pwc/pwc-deals-ui';
import classNames from 'classnames';
import { get } from 'lodash';
import { Skeleton } from '@mui/material';
import platformName from '../common/utilities/get.platform.name';
import { Card } from '../common/components/Card/Card';
import ContentLayout from '../common/components/layout/ContentLayout';
import {
  getProjectData,
  setProjectThunk,
  getProjectConnected
} from '../features/project/project';
import {
  getFullPath,
  setWorkbookThunk,
  getWorkbookName
} from '../features/workbook/workbook';
import {
  getPptGroups,
  getPptGroupPreview,
  copyToClipboard
} from '../common/services/clipboardService';

import '@pwc/pwc-deals-ui/build/styles/main.css';
import CopyErrorMessage from '../common/components/CopyErrorMessage/CopyErrorMessage';
import FileSizeWarning from './ExcelApp/components/FileSizeWarning';
import { useFileSize } from '../common/utilities/useFileSize';

const CLEAR_MESSAGE_TIMEOUT = 10000;

const ObjectPreviewCard = ({
  projectId,
  groupName,
  fileDetails,
  preview,
  activeSlide
}) => {
  const [isHover, setIsHover] = useState();
  const [isAdding, setIsAdding] = useState();
  const [copiedToJunction, setCopiedToJunction] = useState();
  const [errorCopyingToJunction, setErrorCopyingToJunction] = useState();

  const showOverlay = useMemo(() => isHover || isAdding, [isHover, isAdding]);

  const handleAddToJunction = async () => {
    setIsAdding(true);
    setCopiedToJunction(false);
    setErrorCopyingToJunction(false);
    try {
      await copyToClipboard(projectId, {
        type: 500,
        data: JSON.stringify({
          SlideNumber: activeSlide,
          GroupName: groupName,
          // SiteId: TODO where this coming from?
          DriveId: fileDetails.driveId,
          DriveItemId: fileDetails.driveItemId,
          SharePointItemUrl: fileDetails.sharepointItemUrl
        })
      });

      setIsAdding(false);
      setCopiedToJunction(true);
      setTimeout(() => {
        setCopiedToJunction(false);
      }, CLEAR_MESSAGE_TIMEOUT);
    } catch (e) {
      setErrorCopyingToJunction(true);
    } finally {
      setIsAdding(false);
    }
  };

  return (
    <>
      <Card
        className={classNames('ppt-card', {
          'ppt-card--active': copiedToJunction
        })}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {preview ? (
          <div>
            <div
              className="ppt-object-wrapper"
              dangerouslySetInnerHTML={{ __html: preview }}
            />
            {showOverlay && !copiedToJunction && (
              <div className="ppt-card__add">
                {isAdding ? (
                  <MiniLoader />
                ) : (
                  <Button
                    type="button"
                    className="button"
                    onClick={handleAddToJunction}
                  >
                    <Icon name="add" className="icon" width={18} />
                    Add to Junction
                  </Button>
                )}
              </div>
            )}
          </div>
        ) : (
          <MiniLoader />
        )}
      </Card>
      {copiedToJunction && (
        <div className="ppt-card__success">
          <div className="ppt-card__success__icon-wrapper">
            <Icon name="check" className="fill icon-green" />
          </div>

          <p>Object now available in Junction</p>
        </div>
      )}
      {errorCopyingToJunction && <CopyErrorMessage />}
    </>
  );
};

const DEFAULT_GROUPS_TO_TAKE = 4;

const PowerPointApp = () => {
  const dispatch = useDispatch();
  const fullPath = useSelector(getFullPath);
  const project = useSelector(getProjectData);
  const title = useSelector(getWorkbookName);
  const projectConnected = useSelector(getProjectConnected);
  const {
    fileSizeInBytes,
    isLoading: isLoadingFileSize,
    hasError: hasErrorFileSize
  } = useFileSize();

  const [showError, setShowError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [driveDetails, setDriveDetails] = useState({});
  const [hasMore, setHasMore] = useState(false);
  const [activeSlide, setActiveSlide] = useState();
  const [groups, setGroups] = useState([]);
  const groupsToTake = useRef({ current: 0 });
  const errorMessage =
    "There's a problem loading image preview. Please try again later.";

  const getGroups = async currentGroups => {
    setLoading(true);
    setShowError(false);

    const payload = {
      ...driveDetails,
      slides: [
        {
          slideNumber: activeSlide,
          skip: groupsToTake.current * DEFAULT_GROUPS_TO_TAKE,
          take: DEFAULT_GROUPS_TO_TAKE
        }
      ],
      fileName: title,
      sharepointItemUrl: fullPath
    };

    try {
      const { data = {} } = await getPptGroupPreview(project.id, payload);
      const pptData = get(data, 'data.slides', [{}])[0];
      const pptGroups = get(pptData, 'groups', []);
      const pptHasMore = get(pptData, 'hasMore', false);

      const concatGroups = [...(currentGroups || groups), ...pptGroups];
      setHasMore(pptHasMore);
      setGroups(concatGroups);
      setLoading(false);
      setShowError(false);
    } catch (e) {
      setLoading(false);
      setShowError(true);
    }
  };

  useEffect(() => {
    if (project && driveDetails.driveId && driveDetails.driveItemId) {
      getGroups([]);
    }
  }, [driveDetails, activeSlide]);

  useEffect(() => {
    dispatch(setWorkbookThunk(window.Office.context.document));
    dispatch(setProjectThunk(fullPath));
  }, [dispatch, fullPath]);

  useEffect(() => {
    if (project) {
      setShowError(false);
      getPptGroups(project.id, {
        fileName: title,
        sharepointItemUrl: fullPath
      })
        .then(({ data }) => {
          const { driveId, driveItemId } = data.data;
          setDriveDetails({
            driveId,
            driveItemId
          });
        })
        .catch(() => {
          setLoading(false);
          setShowError(true);
        });
    } else {
      setLoading(false);
    }
  }, [project]);

  const getAndSetActiveSlide = useCallback(() => {
    window.Office.context.document.getSelectedDataAsync(
      window.Office.CoercionType.SlideRange,
      asyncResult => {
        const newSlideIdx = asyncResult.value.slides[0].index;
        if (activeSlide !== newSlideIdx) {
          setGroups([]);
          setHasMore(false);
          groupsToTake.current = 0;
          setActiveSlide(newSlideIdx);
        }
      }
    );
  }, [activeSlide]);

  useEffect(() => {
    const timer = setInterval(() => {
      getAndSetActiveSlide();
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, [activeSlide]);

  const handleLoadMore = async () => {
    const currentGroupsToTake = groupsToTake.current + 1;
    groupsToTake.current = currentGroupsToTake;

    await getGroups();
  };

  const handleRefreshData = async () => {
    setGroups([]);
    setHasMore(false);
    groupsToTake.current = 0;
    await getGroups([]);
  };

  if (projectConnected != null && !projectConnected) {
    return (
      <main>
        <CopyErrorMessage
          message={`File not connected to a ${platformName} Project`}
        />
      </main>
    );
  }

  if (isLoadingFileSize) {
    return (
      <ContentLayout title={project && project.name}>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={100}
          animation="wave"
        />
      </ContentLayout>
    );
  }

  return (
    <ContentLayout title={project && project.name}>
      <FileSizeWarning
        fileSizeInBytes={fileSizeInBytes}
        hasErrorFileSize={hasErrorFileSize}
      >
        <>
          {!loading && (
            <div className="refresh-icon-container">
              <Tooltip direction="bottom" tip="Refresh">
                <div className="refresh-icon">
                  <Icon name="refresh" onClick={handleRefreshData} />
                </div>
              </Tooltip>
            </div>
          )}
          <p>
            Choose an object and click the &apos;Add to Junction&apos; button
          </p>
          {loading && (
            <div className="ppt-mini-loader-contain">
              <MiniLoader />
            </div>
          )}
          {groups.map(({ groupName, preview }) => {
            return (
              <ObjectPreviewCard
                key={groupName}
                preview={preview}
                projectId={project.id}
                groupName={groupName}
                activeSlide={activeSlide}
                fileDetails={{
                  ...driveDetails,
                  sharepointItemUrl: fullPath
                }}
              />
            );
          })}
          {hasMore && (
            <Button
              type="button"
              className="button"
              style={{ width: '100%' }}
              disabled={loading}
              onClick={handleLoadMore}
            >
              Load More
            </Button>
          )}
          {showError != null && showError && (
            <CopyErrorMessage message={errorMessage} />
          )}
        </>
      </FileSizeWarning>
    </ContentLayout>
  );
};

export default PowerPointApp;
