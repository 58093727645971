const updateRange = async context => {
  const { worksheet, rangeToReplace, excelContext, rowId } = context;
  const platformName = process.env.REACT_APP_PLATFORM_NAME;

  if (rangeToReplace.length === 0) {
    return Promise.resolve();
  }

  const ranges = rangeToReplace.map(cells => {
    return cells.map(cell => worksheet.getRange(cell));
  });

  const isValueForRowId = index => index === 0;

  ranges.forEach(r1 =>
    r1.forEach((range, index) => {
      if (isValueForRowId(index)) {
        range.set({
          values: [[rowId]]
        });
      } else {
        range.set({
          format: {
            fill: {
              color: '#dedede'
            },
            font: {
              color: '#ce4a1a'
            }
          },
          values: [[`Content available to ${platformName}`]]
        });
      }
    })
  );

  return excelContext
    .sync()
    .catch(() => {
      throw new Error('Error updating workbook');
    })
    .then(() => Promise.resolve(context));
};

export default updateRange;
