import { createSlice } from '@reduxjs/toolkit';

export const getWorkbook = state => state.workbook;
export const getWorkbookName = state => state.workbook.name;

export const initialState = { fullPath: null };

const workbookSlice = createSlice({
  name: 'workbook',
  initialState,
  reducers: {
    setWorkbookSucceed: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    }
  }
});

export const getFullPath = state => state.workbook.fullPath;

export const { setWorkbookSucceed } = workbookSlice.actions;

export default workbookSlice.reducer;

const getFileLocationIfExists = response =>
  response.value ? response.value.url : 'Could not find file location';

export const setWorkbookThunk = document => async dispatch => {
  document.getFilePropertiesAsync(async response => {
    const fullPath = getFileLocationIfExists(response);
    let name;
    if (fullPath) {
      name = fullPath.split('/').pop();
    }
    dispatch(setWorkbookSucceed({ fullPath, name }));
  });
};
