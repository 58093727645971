import {
  ClipboardHttpClient,
  LibraryItemsExcelHttpClient,
  LibraryItemsPptHttpClient,
  LibraryItemsClipboardHttpClient
} from './apiClients';

import { useNamedRanges } from '../utilities/excelUtilities';

export const copyToClipboard = async (projectId, data) => {
  return ClipboardHttpClient.post({
    relativePath: `projects/${projectId}/libraryitem`,
    data
  });
};

export const postChart = async (projectId, data) => {
  return ClipboardHttpClient.post({
    relativePath: `projects/${projectId}/chart`,
    data
  });
};

export const postCellRange = async (projectId, data) => {
  if (useNamedRanges) {
    return ClipboardHttpClient.post({
      relativePath: `projects/${projectId}/namedRange`,
      data
    });
  }

  return ClipboardHttpClient.post({
    relativePath: `projects/${projectId}/cellRange`,
    data
  });
};

export const postDriveDetails = async (projectId, data) => {
  return ClipboardHttpClient.post({
    relativePath: `projects/${projectId}/driveDetails`,
    data
  });
};

export const getPptGroups = async (projectId, data) => {
  return LibraryItemsPptHttpClient.post({
    relativePath: `PPTGroupObject/${projectId}`,
    data
  });
};

export const getPptGroupPreview = async (projectId, data) => {
  return LibraryItemsPptHttpClient.post({
    relativePath: `PPTGroupObject/${projectId}/previewsBySlide`,
    data
  });
};

export const getPptObjectPreview = async (projectId, data) => {
  return LibraryItemsPptHttpClient.post({
    relativePath: `PPTGroupObject/${projectId}/preview`,
    data
  });
};

export const getLibraryItemsFromClipboard = async projectId => {
  const response = await ClipboardHttpClient.get({
    relativePath: `projects/${projectId}/libraryitem`
  });
  return response.data;
};

export const getExcelLibraryItemsByGroup = async (projectId, data) => {
  return LibraryItemsExcelHttpClient.post({
    relativePath: `projects/${projectId}`,
    data
  }).then(r => r.data.data);
};

export const getPptLibraryItemsByGroup = async (projectId, data) => {
  return LibraryItemsPptHttpClient.post({
    relativePath: `projects/${projectId}`,
    data
  }).then(r => r.data.data);
};

export const clearClipboard = async (projectId, requestData) => {
  const response = await LibraryItemsClipboardHttpClient.put({
    relativePath: `/projects/${projectId}/libraryitem`,
    data: {
      ...requestData,
      status: null,
      data: JSON.stringify(requestData.data)
    }
  });
  return response.data;
};
