import { Box, Typography } from '@mui/material';
import React from 'react';

const ContentLayout = ({ testId = 'project-name', title, children }) => {
  return (
    <Box sx={{ m: '1rem' }}>
      {title && (
        <Typography
          data-testid={testId}
          sx={{ my: '1rem', fontSize: '1.375rem', fontWeight: 'bold' }}
        >
          {title}
        </Typography>
      )}
      {children}
    </Box>
  );
};

export default ContentLayout;
