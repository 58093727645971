const jwtTokenKey = 'jwtToken';
const processTimeKey = 'processTime';

export const saveApplicationToken = token =>
  sessionStorage.setItem(jwtTokenKey, token);

export const getApplicationToken = () => sessionStorage.getItem(jwtTokenKey);
export const removeApplicationToken = () =>
  sessionStorage.removeItem(jwtTokenKey);

const buildProjectIdTokenStorageKey = projectId =>
  `projectJWT${btoa(projectId)}`;

export const saveProjectToken = (projectId, token) => {
  sessionStorage.setItem(buildProjectIdTokenStorageKey(projectId), token);
};

export const getProjectToken = projectId => {
  return sessionStorage.getItem(buildProjectIdTokenStorageKey(projectId));
};

export const getProcessTime = () => sessionStorage.getItem(processTimeKey);
export const saveProcessTime = time =>
  sessionStorage.setItem(processTimeKey, time);
