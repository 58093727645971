export async function getChartsInWorkbook(excelContext) {
  const sheets = excelContext.workbook.worksheets;
  sheets.load('items');
  await excelContext.sync();

  const worksheetMap = [];

  sheets.items.forEach(sheet => {
    sheet.load('charts');
    sheet.load('name');
  });
  await excelContext.sync();

  sheets.items.forEach(sheet => {
    worksheetMap.push({
      worksheetName: sheet.name,
      charts: sheet.charts.items
    });
  });

  worksheetMap.forEach(sheet => {
    sheet.charts.forEach(chart => {
      chart.load('id');
      chart.load('name');
      chart.load('title');
    });
  });

  await excelContext.sync();

  const chartMap = [];
  worksheetMap.forEach(({ worksheetName, charts }) => {
    charts.forEach(chart =>
      chartMap.push({
        id: chart.id,
        name: chart.name,
        label: chart.title.text,
        onActivated: chart.onActivated,
        worksheetName,
        value: `${worksheetName}:${chart.name}`
      })
    );
  });

  return chartMap;
}
