/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useExcelContext } from '../../common/components/Excel/useExcelContext';
import ExcelProject from '../../common/components/layout/ExcelProject';
import authorizationService from '../../common/services/security/authorizationService';
import platformName from '../../common/utilities/get.platform.name';
import Excel from '../../common/components/Excel/Excel';
import ActionPanel from '../components/ActionPanel';
import {
  getProjectData,
  getProjectProcessing,
  processingComplete,
  processingStart,
  setProjectThunk
} from '../project/project';
import Navigation from '../../common/components/layout/Navigation';
import { getFullPath, getWorkbookName } from '../workbook/workbook';
import {
  getChildItemLinkIdsFromHtml,
  getLongDescriptionWithoutSpecifiedChildren
} from './services/ckEditorHtmlHelper';
import { getDocument } from './services/getColumnData';
import { getRichText, sendRichText } from './services/richTextService';
import RichTextContainerEditMode from './RichTextContainerEditMode';
import {
  buildInputForSave,
  buildRowErrorMessage,
  errorsArrayToDivString,
  getRowsForSaveAndValidationErrors
} from './services/bulkCaptureHelper';

const RichTextContainer = () => {
  const fullPath = useSelector(getFullPath);
  const workbookName = useSelector(getWorkbookName);
  const project = useSelector(getProjectData);
  const projectId = project ? project.id : null;
  const dispatch = useDispatch();
  const isLoading = useSelector(getProjectProcessing);

  const [documentId, setDocumentId] = useState(null);
  const [statusMessage, setStatusMessage] = useState('');

  const [needsReingestionMessage, setNeedsReingestionMessage] = useState('');
  const [captureAllStatusMessage, setCaptureAllStatusMessage] = useState('');
  const [
    captureAllValidationMessage,
    setCaptureAllValidationMessage
  ] = useState('');
  const [
    captureAllNeedsReingestionMessage,
    setCaptureAllNeedsReingestionMessage
  ] = useState('');

  const [captureProcessStatus, setCaptureProcessStatus] = useState('');

  const [shortDescription, setShortDescription] = useState(null);
  const [richText, setRichText] = useState(null);
  const [newLongDescription, setNewLongDescription] = useState(null);
  const [adjustmentNumber, setAdjustmentNumber] = useState(null);
  const [rowId, setRowId] = useState(null);
  const [libraryItemId, setLibraryItemId] = useState(null);
  const [documentCode, setDocumentCode] = useState(null);
  const [loadedWorksheetName, setLoadedWorksheetName] = useState(null);
  const [worksheet, setWorksheet] = useState(null);
  const excelContext = useExcelContext();
  const [columns, setColumns] = useState({});
  const [registeredLinks, setRegisteredLinks] = useState([]);
  const [editorError, setEditorError] = useState(false);

  const updateCellInformation = async () => {
    dispatch(processingStart());
    // clear all messages
    setStatusMessage('');
    setNeedsReingestionMessage('');
    setCaptureAllStatusMessage('');
    setCaptureAllValidationMessage('');
    setCaptureAllNeedsReingestionMessage('');
    setCaptureProcessStatus('');

    getRichText({
      excelContext,
      projectId: project.id,
      workbookName,
      loadedWorksheetName,
      ...columns,
      documentId,
      documentCode
    })
      .then(context => {
        setDocumentId(context.documentId);
        setShortDescription(context.shortDescription);
        setAdjustmentNumber(context.adjustmentNumber);
        setRowId(context.rowId);
        setRichText({
          text: context.longDescription,
          links: context.links
        });
        setLibraryItemId(context.libraryItemId);
        setDocumentCode(context.documentCode);
        setWorksheet(context.worksheet);
        setLoadedWorksheetName(context.worksheetName);
        setColumns({
          adjustmentNumberColumnIndex: context.adjustmentNumberColumnIndex,
          shortDescriptionColumnIndex: context.shortDescriptionColumnIndex,
          longDescriptionColumnIndex: context.longDescriptionColumnIndex,
          rowIdColumnIndex: context.rowIdColumnIndex,
          columnOffset: context.columnOffset
        });
      })
      .catch(e => {
        setCaptureProcessStatus('failure');
        setStatusMessage(e.message);
      })
      .then(() => {
        dispatch(processingComplete());
      });
  };

  const captureSingleLongDescriptionForSelectedCell = () => {
    dispatch(processingStart());
    const notFoundIds = richText.links.filter(l => l.notFound).map(l => l.id);
    const childLinkIds = registeredLinks.filter(
      id => !notFoundIds.includes(id)
    );
    const correctedLongDescription = getLongDescriptionWithoutSpecifiedChildren(
      newLongDescription,
      notFoundIds
    );

    const usedRange = worksheet.getUsedRange();
    usedRange.load('address,values');

    sendRichText({
      documentId,
      libraryItemId,
      longDescription: correctedLongDescription,
      documentCode,
      shortDescription,
      projectId: project.id,
      worksheet,
      excelContext,
      ...columns,
      registeredLinks: childLinkIds,
      adjustmentNumber: adjustmentNumber || '',
      rowId,
      usedRange
    })
      .then(({ exists }) => {
        setShortDescription(null);
        setRichText(null);
        setCaptureProcessStatus('success');
        setStatusMessage(`Rich Text successfully saved to ${platformName}.`);
        if (!exists) {
          setNeedsReingestionMessage(
            'Please re-ingest the latest version of the Excel file. You’ll need to do this every time a new long description is added to Junction.'
          );
        }
      })
      .catch(e => {
        setCaptureProcessStatus('failure');
        setStatusMessage(e.message);
      })
      .then(() => {
        dispatch(processingComplete());
      });
  };

  const handleEditorChange = newValue => {
    if (newValue !== richText) {
      authorizationService.updateProcessTime();
    }
    setNewLongDescription(newValue);

    const childLinkIds = getChildItemLinkIdsFromHtml(newValue);
    setRegisteredLinks(childLinkIds);
  };

  const processAllLongDescriptions = async () => {
    dispatch(processingStart());
    setStatusMessage('');
    setNeedsReingestionMessage('');
    setCaptureAllStatusMessage('');
    setCaptureAllValidationMessage('');
    setCaptureAllNeedsReingestionMessage('');
    setCaptureProcessStatus('');

    const localWorkSheet = excelContext.workbook.worksheets.getActiveWorksheet();
    localWorkSheet.load('name');

    const usedRange = localWorkSheet.getUsedRange();
    usedRange.load('address,values');

    // ***
    // HACK ALERT: We tried chaining these with then and catch, but could never
    // get the process to break correctly on error, it just kept going
    let errorMessage = '';
    await excelContext.sync().catch(e => {
      errorMessage = e.message;
    });

    if (errorMessage) {
      setCaptureProcessStatus('failure');
      setCaptureAllStatusMessage(errorMessage);
      dispatch(processingComplete());
      return;
    }

    const { id, code, configurationValues, columnOffset } = await getDocument(
      workbookName,
      localWorkSheet.name,
      projectId
    );

    const amountOfHeaderBufferRows = 1;
    const firstRowOfDataIndex =
      configurationValues.rawDataHeaderRowIndex + amountOfHeaderBufferRows;

    const { rowsForSave, validationErrors } = getRowsForSaveAndValidationErrors(
      firstRowOfDataIndex,
      configurationValues,
      usedRange.values
    );

    if (validationErrors.length > 0) {
      setCaptureProcessStatus('failure');
      setCaptureAllValidationMessage(errorsArrayToDivString(validationErrors));
    }

    const inputBuilder = buildInputForSave({
      documentId: id,
      documentCode: code,
      projectId,
      localWorkSheet,
      excelContext,
      columnOffset,
      configurationValues,
      usedRange
    });

    if (rowsForSave.length === 0) {
      dispatch(processingComplete());
      return;
    }

    Promise.all(
      rowsForSave.map(async row => {
        const input = inputBuilder(row);
        const capturePromise = sendRichText(input).then(
          () =>
            Promise.resolve({
              status: 'success'
            }),
          response => {
            return Promise.resolve({
              status: 'rejected',
              message: buildRowErrorMessage(input.rowNumber, response.message)
            });
          }
        );

        return capturePromise;
      })
    ).then(promiseResults => {
      const rejectedResults = promiseResults
        .filter(r => r.status === 'rejected')
        .map(e => e.message);

      if (isEmpty(rejectedResults)) {
        const hasValidationErrors = captureProcessStatus !== 'success';
        if (!hasValidationErrors) {
          setCaptureProcessStatus('success');
        }
        setCaptureAllStatusMessage(
          `All Rich Text successfully saved to ${platformName}`
        );
        setCaptureAllNeedsReingestionMessage(
          'Please re-ingest the latest version of the Excel file. You’ll need to do this every time a new long description is added to Junction.'
        );
        dispatch(processingComplete());
      } else {
        setCaptureProcessStatus('failure');
        setCaptureAllStatusMessage(errorsArrayToDivString(rejectedResults));
        dispatch(processingComplete());
      }
    });
  };

  useEffect(() => {
    dispatch(setProjectThunk(fullPath));
  }, [dispatch, fullPath, projectId]);

  return (
    <>
      <Excel />
      <Navigation />
      <ExcelProject excelContext={excelContext}>
        {!richText && (
          <ActionPanel
            name="rich-text-edit"
            title="Rich Text Editor"
            descriptionText="Use rich text to add charts or format text within a cell."
            buttonText="Edit selected cell"
            buttonTestId="rich-text-edit"
            handleOnClick={updateCellInformation}
            isProcessing={isLoading}
            statusMessage={{
              type: captureProcessStatus,
              text: statusMessage
            }}
            supplementalMessage={{
              type: captureProcessStatus,
              text: needsReingestionMessage
            }}
          />
        )}

        {richText && (
          <RichTextContainerEditMode
            projectId={projectId}
            excelContext={excelContext}
            shortDescription={shortDescription}
            richText={richText}
            registeredLinks={registeredLinks}
            editorError={editorError}
            statusMessage={{
              type: captureProcessStatus,
              text: statusMessage
            }}
            isLoading={isLoading}
            setEditorError={setEditorError}
            clearAllStatusMessages={() => {
              setStatusMessage('');
              setNeedsReingestionMessage('');
              setCaptureAllStatusMessage('');
              setCaptureAllValidationMessage('');
              setCaptureAllNeedsReingestionMessage('');
              setCaptureProcessStatus('');
            }}
            setRichText={setRichText}
            handleEditorChange={handleEditorChange}
            captureRichText={captureSingleLongDescriptionForSelectedCell}
          />
        )}

        <ActionPanel
          name="capture-all"
          title="Upload all descriptions"
          descriptionText={`Transfer all text in "Long Description" column to ${platformName}. Once complete, you can edit text directly from the add-in or ${platformName}.`}
          buttonText="Upload all"
          needsVerification
          verificationMessage="Are you sure you want to upload all descriptions?"
          buttonTestId="capture-all-submit"
          handleOnClick={processAllLongDescriptions}
          isProcessing={isLoading}
          statusMessage={{
            type: captureProcessStatus,
            text: captureAllValidationMessage + captureAllStatusMessage
          }}
          supplementalMessage={{
            status: captureProcessStatus,
            text: captureAllNeedsReingestionMessage
          }}
        />
      </ExcelProject>
    </>
  );
};

export default RichTextContainer;
