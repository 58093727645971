import React, { useState } from 'react';
import NamedRangePicker from '../../common/components/NamedRangePicker/NamedRangePicker';
import { postNamedRange } from '../../common/services/libraryItemsService';
import platformName from '../../common/utilities/get.platform.name';

const NamedRangePickerContainer = ({ project, fullPath, excelContext }) => {
  const [statusMessage, setStatusMessage] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const pushNamedRangeToD3 = params => {
    setIsLoading(true);

    postNamedRange(project.id, {
      ...params,
      sharePointItemUrl: fullPath
    })
      .then(() => {
        setStatusMessage({
          text: `Named range successfully saved in ${platformName}!`,
          type: 'success'
        });
        setIsLoading(false);
      })
      .catch(() => {
        setStatusMessage({
          text:
            'An error occurred while saving your named range. Please try again.',
          type: 'failure'
        });
        setIsLoading(false);
      });
  };

  return (
    <NamedRangePicker
      onSave={pushNamedRangeToD3}
      submitText={`Push to ${platformName}`}
      statusMessage={statusMessage}
      pickerTitle="Named Range Ingestion"
      excelContext={excelContext}
      setStatusMessage={setStatusMessage}
      isLoading={isLoading}
    />
  );
};

export default NamedRangePickerContainer;
