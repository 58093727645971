import { RefreshTokenClient, UserAccessTokenClient } from '../apiClients';

const getAuthenticationUrl = async (redirectUri, state) => {
  const response = await UserAccessTokenClient.get({
    relativePath: `access/authenticationUrl?redirectUri=${redirectUri}&state=${state}`
  });
  return response.data;
};

/**
 * @param {string} accessCode
 * @returns {Promise<{ applicationToken, projectTokens[{projectId, token}], unreadTermsAndConditions[]} >}
 */
const getTokens = async accessCode => {
  const response = await UserAccessTokenClient.get({
    relativePath: `access/tokens?code=${accessCode}&redirectUri=${window.location.origin}`
  });
  return response.data.data;
};

const refreshTokens = async () => {
  const response = await RefreshTokenClient.get({
    relativePath: `access/refreshTokens?redirectUri=${window.location.origin}`
  });
  return response.data.data;
};

const userTokenService = {
  getAuthenticationUrl,
  getTokens,
  refreshTokens
};

export default userTokenService;
