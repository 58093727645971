export const getChildItemLinkIdsFromHtml = html => {
  const re = /class="placeholder ck-widget" id="(\d+)"/g;
  const ids = [];
  let match;
  // eslint-disable-next-line no-cond-assign
  while ((match = re.exec(html)) !== null) {
    const id = parseInt(match[1], 10);
    ids.push(id);
  }
  return ids;
};

export const getLongDescriptionWithoutSpecifiedChildren = (
  longDescription,
  childIdsToRemove
) =>
  childIdsToRemove.reduce((ld, id) => {
    const childRegex = new RegExp(
      `<span class="placeholder ck-widget" id="${id}" contenteditable="(false|true)"></span>`,
      'g'
    );
    return ld.replace(childRegex, '');
  }, longDescription || '');

export const getLongDescriptionWithOnlySpecifiedChildren = (
  longDescription,
  allowedChildIds
) => {
  const childIdsFromHtml = getChildItemLinkIdsFromHtml(longDescription);
  const childIdsToRemove = childIdsFromHtml.filter(
    id => !allowedChildIds.includes(id)
  );
  return getLongDescriptionWithoutSpecifiedChildren(
    longDescription,
    childIdsToRemove
  );
};
