import { StructuredAnalysisHttpClient } from '../../../common/services/apiClients';

const platformName = process.env.REACT_APP_PLATFORM_NAME;

export const getExcelColumnIndex = column => {
  return column.charCodeAt(0) - 65;
};

export const getDocument = async (workbookName, worksheetName, projectId) => {
  let response;
  try {
    response = await StructuredAnalysisHttpClient.get({
      relativePath: `projects/${projectId}/StructuredAnalysisDocuments/search?workbookName=${workbookName}&worksheetName=${worksheetName}`
    });
  } catch (e) {
    throw new Error(`Could not retrieve column data from ${platformName}`);
  }
  const body = response.data.data;

  if (body.length === 0) {
    throw new Error(`This worksheet is not in ${platformName}`);
  }

  return body[0];
};

const getColumnData = async context => {
  const {
    workbookName,
    worksheetName,
    projectId,
    selectedColumn,
    loadedWorksheetName
  } = context;

  let columnData;
  const alreadyLoaded = worksheetName === loadedWorksheetName;
  if (!alreadyLoaded) {
    const document = await getDocument(workbookName, worksheetName, projectId);

    const {
      id: documentId,
      code: documentCode,
      configurationValues,
      columnOffset
    } = document;

    // columnOffset ?? PWC has sometimes had "gutter" columns in the data on the left most margin.
    // The gutter columns aren't pushed to our APIs b/c they get stripped out.
    // The column indexes we have defined are relative to the data our API has
    // so the excel-addin to access columns properly by index we need to always add the column offset.

    columnData = {
      columnOffset,
      documentId,
      shortDescriptionColumnIndex:
        configurationValues.adjustmentShortDescColumnIndex,
      longDescriptionColumnIndex:
        configurationValues.adjustmentLongDescColumnIndex,
      adjustmentNumberColumnIndex:
        configurationValues.adjustmentNumberColumnIndex,
      rowIdColumnIndex: configurationValues.adjustmentRowIdColumnIndex,
      documentCode
    };

    const isValidColumnData =
      columnData.shortDescriptionColumnIndex &&
      columnData.longDescriptionColumnIndex &&
      columnData.adjustmentNumberColumnIndex;

    if (!isValidColumnData) {
      throw new Error(`Error retrieving column data from ${platformName}`);
    }
  } else {
    columnData = context;
  }

  if (
    getExcelColumnIndex(selectedColumn) !==
    columnData.longDescriptionColumnIndex
  ) {
    throw new Error('Please select a Long Description');
  }

  const data = {
    ...context,
    ...columnData
  };

  return data;
};

export default getColumnData;
