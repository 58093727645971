const hideAdjustments =
  // eslint-disable-next-line no-undef
  (process.env.REACT_APP_HIDE_ADJUSTMENTS || '').trim().replace(/\W/g, '') ===
  'true';

const showFileSizeWarning =
  // eslint-disable-next-line no-undef
  (process.env.REACT_APP_SHOW_FILE_SIZE_WARNING || '')
    .trim()
    .replace(/\W/g, '') === 'true';

const fileSizeLimitMbSoftWarning =
  // eslint-disable-next-line no-undef
  parseInt(
    (process.env.REACT_APP_FILE_SIZE_LIMIT_MB_SOFT_WARNING || '')
      .trim()
      .replace(/\W/g, ''),
    10
  );

const fileSizeLimitMbHardWarning =
  // eslint-disable-next-line no-undef
  parseInt(
    (process.env.REACT_APP_FILE_SIZE_LIMIT_MB_HARD_WARNING || '')
      .trim()
      .replace(/\W/g, ''),
    10
  );

export const featureFlags = {
  hideAdjustments,
  showFileSizeWarning,
  fileSizeLimitMbSoftWarning,
  fileSizeLimitMbHardWarning
};
