import getRangeAndSheet from './getRangeAndSheet';
import getColumnData from './getColumnData';
import getShortDescriptionCell from './getShortDescriptionCell';
import getLibraryItemLink from './getLibraryItemLink';
import getLibraryItem from './getLibraryItem';
import getAdjustmentNumberCell from './getAdjustmentNumberCell';
import setRichText from './setRichText';
import createLinkLibraryItem from './createLinkLibraryItem';
import getRangeToReplace from './getRangeToReplace';
import updateRange from './updateRange';
import getRowIdCell from './getRowIdCell';

export const getRichText = async context => {
  const rangeAndSheet = await getRangeAndSheet(context);
  const columnData = await getColumnData(rangeAndSheet);
  const shortDescriptionCell = await getShortDescriptionCell(columnData);
  const adjustmentNumberCell = await getAdjustmentNumberCell(columnData);
  const rowIdCell = await getRowIdCell(columnData);
  const libraryItemLink = await getLibraryItemLink(
    context.projectId,
    columnData.documentId,
    rowIdCell.rowId
  );

  const libraryItem = await getLibraryItem(context.projectId, libraryItemLink);

  if (libraryItem) {
    return {
      ...shortDescriptionCell,
      libraryItemId: libraryItem.id,
      longDescription: libraryItem.data.richTextData,
      links: libraryItem.childrenData,
      adjustmentNumber: adjustmentNumberCell.adjustmentNumber,
      rowId: rowIdCell.rowId
    };
  }

  return {
    ...shortDescriptionCell,
    libraryItemId: '',
    longDescription: rangeAndSheet.longDescription,
    links: [],
    adjustmentNumber: adjustmentNumberCell.adjustmentNumber,
    rowId: rowIdCell.rowId
  };
};

export const sendRichText = async context =>
  setRichText(context)
    .then(createLinkLibraryItem)
    .then(getRangeToReplace)
    .then(updateRange);
