import {
  Box,
  ClickAwayListener,
  IconButton,
  Link,
  styled,
  Tooltip,
  tooltipClasses
} from '@mui/material';
import React, { useState } from 'react';
import { Icon } from '@pwc/pwc-deals-ui';
import classNames from 'classnames';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '1rem',
    fontSize: '0.875rem',
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 0px 6px rgba(0,0,0,0.2)'
  },
  [`& .${tooltipClasses.tooltipPlacementBottom}`]: {
    marginTop: '0.25rem !important',
    '& .MuiTooltip-arrow::before': {
      boxShadow: '0px 0px 6px rgba(0,0,0,0.1)'
    }
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white
  }
}));

export const TooltipPopup = ({ tooltipLabel, tooltipLink }) => {
  const [open, setOpen] = useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleHelpClick = () => {
    setOpen(prev => !prev);
  };

  const TooltipTitle = (
    <Link href={tooltipLink} target="_blank" rel="noopener noreferrer">
      {tooltipLabel}
    </Link>
  );

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Box>
        <LightTooltip
          title={TooltipTitle}
          arrow
          PopperProps={{
            disablePortal: true
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <IconButton aria-label="help" onClick={handleHelpClick} sx={{ p: 0 }}>
            <Icon
              name="help-question"
              width="24"
              className={classNames('icon-orange', open ? 'fill' : 'outline')}
            />
          </IconButton>
        </LightTooltip>
      </Box>
    </ClickAwayListener>
  );
};
