import React from 'react';
import InfoBox from '../../components/InfoBox';
import { featureFlags } from '../../../common/helpers/featureFlag';

const MB = 1000000;

const FILE_SIZE_HARD_LIMIT_MB = featureFlags.fileSizeLimitMbHardWarning || 1000;
const FILE_SIZE_HARD_LIMIT = FILE_SIZE_HARD_LIMIT_MB * MB;

const FILE_SIZE_SOFT_LIMIT_MB = featureFlags.fileSizeLimitMbSoftWarning || 15;
const FILE_SIZE_SOFT_WARNING = FILE_SIZE_SOFT_LIMIT_MB * MB;

const relativeFileSizeEnum = {
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  OK: 'OK'
};

const getFileRelativeSize = fileSizeInBytes => {
  if (!fileSizeInBytes) {
    return null;
  }
  if (fileSizeInBytes > FILE_SIZE_HARD_LIMIT) {
    return relativeFileSizeEnum.ERROR;
  }
  if (fileSizeInBytes >= FILE_SIZE_SOFT_WARNING) {
    return relativeFileSizeEnum.WARNING;
  }
  return relativeFileSizeEnum.OK;
};

const FileSizeWarning = ({ hasErrorFileSize, fileSizeInBytes, children }) => {
  if (hasErrorFileSize) {
    return (
      <>
        <InfoBox
          title="Unable to check file size"
          description="Junction performs best with small files."
          isWarning
        />
        {children}
      </>
    );
  }

  const relativeFileSize = getFileRelativeSize(fileSizeInBytes);

  if (relativeFileSize === relativeFileSizeEnum.ERROR) {
    return (
      <InfoBox
        title="File size exceeds limit"
        description={`This file can’t be used in Junction as it exceeds the ${FILE_SIZE_HARD_LIMIT_MB}MB limit.
              Please reduce the file size and try again. Junction will perform
              better if you split large files into multiple smaller files and
              remove content that's not required.`}
        isError
      />
    );
  }

  if (relativeFileSize === relativeFileSizeEnum.WARNING) {
    return (
      <>
        <InfoBox
          title="File size may affect performance"
          description={`This file is larger than the recommended max (8 MB). You can split this file into smaller files to reduce reduce the file size. You can also switch off Autosync in Settings/Preferences to improve Junction's performance`}
          isWarning
        />
        {children}
      </>
    );
  }

  return children;
};

export default FileSizeWarning;
