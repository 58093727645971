import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Button } from '@pwc/pwc-deals-ui';
import RichTextEditor from './RichTextEditor/RichTextEditor';
import InlineMessage from '../../common/components/InlineMessage/InlineMessage';

const RichTextContainerEditMode = ({
  excelContext,
  shortDescription,
  richText,
  registeredLinks,
  editorError,
  statusMessage,
  isLoading,
  setEditorError,
  clearAllStatusMessages,
  setRichText,
  handleEditorChange,
  captureRichText,
  projectId,
  sharePointSiteUrl
}) => (
  <div className="card" data-testid="rich-text">
    <h3 className="card-title" data-testid="rich-text-title">
      {shortDescription}
    </h3>
    <RichTextEditor
      excelContext={excelContext}
      onChange={handleEditorChange}
      richText={richText}
      registeredLinks={registeredLinks}
      setRegisteredLinks={noop}
      editorError={editorError}
      setEditorError={setEditorError}
      projectId={projectId}
      sharePointSiteUrl={sharePointSiteUrl}
    />
    <InlineMessage text={statusMessage.text} type={statusMessage.type} />
    <div className="card-actions">
      <Button
        type="button"
        className="button"
        onClick={() => captureRichText()}
        disabled={isLoading || editorError}
        data-testid="rich-text-submit"
      >
        Publish
      </Button>
      <Button
        type="button"
        className="button button-link"
        onClick={() => {
          setRichText(null);
          clearAllStatusMessages();
          setEditorError(false);
        }}
        disabled={isLoading}
        data-testid="rich-text-cancel"
      >
        Cancel
      </Button>
    </div>
  </div>
);

RichTextContainerEditMode.propTypes = {
  excelContext: PropTypes.shape({}).isRequired,
  shortDescription: PropTypes.string,
  richText: PropTypes.shape({
    text: PropTypes.string,
    links: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.number, htmlData: PropTypes.string })
    )
  }),
  registeredLinks: PropTypes.arrayOf(PropTypes.number),
  editorError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setEditorError: PropTypes.func.isRequired,
  clearAllStatusMessages: PropTypes.func.isRequired,
  setRichText: PropTypes.func.isRequired,
  handleEditorChange: PropTypes.func.isRequired,
  captureRichText: PropTypes.func.isRequired,
  statusMessage: PropTypes.shape({
    text: PropTypes.string,
    type: PropTypes.string
  })
};

RichTextContainerEditMode.defaultProps = {
  shortDescription: '',
  richText: {
    text: '',
    links: []
  },
  registeredLinks: [],
  statusMessage: { text: '', type: '' }
};

export default RichTextContainerEditMode;
