import { createSlice } from '@reduxjs/toolkit';

export const initialState = null;

const loggedInUserSlice = createSlice({
  name: 'loggedInUser',
  initialState,
  reducers: {
    setLoggedInUserSucceeded: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    }
  }
});

export const { setLoggedInUserSucceeded } = loggedInUserSlice.actions;

export default loggedInUserSlice.reducer;
