import React, { useEffect, useState } from 'react';
import { Button, Select } from '@pwc/pwc-deals-ui';
import { addOnActivatedEventHandlerToCharts } from './addOnActivatedEventHandlerToCharts';
import { useActiveWorksheet } from '../../services/worksheetService';
import { getChartsInWorkbook } from './chartService';
import InlineMessage from '../InlineMessage/InlineMessage';
import Loading from '../Loading/Loading';
import { replaceWhitespaceCharacters } from '../../utilities/replaceWhitespaceCharacters';
import './ChartPicker.scss';

const ChartPicker = ({
  onCancel,
  onSave,
  excelContext,
  submitText,
  statusMessage,
  setStatusMessage,
  pickerTitle,
  isLoading
}) => {
  const [charts, setCharts] = useState(null);
  const [selectedChart, setSelectedChart] = useState({});
  const activeWorksheet = useActiveWorksheet(excelContext);

  useEffect(() => {
    if (activeWorksheet) {
      (async () => {
        const workbookCharts = await getChartsInWorkbook(excelContext);
        if (workbookCharts) setCharts(workbookCharts);
      })();
    }
  }, [excelContext, activeWorksheet]);

  useEffect(() => {
    addOnActivatedEventHandlerToCharts(
      excelContext,
      charts,
      setSelectedChart,
      setStatusMessage
    );
  }, [excelContext, charts, setStatusMessage]);

  return (
    <div className="card" data-testid="chart-picker">
      <h3 className="card-title">{pickerTitle}</h3>
      {!charts && <Loading />}
      {charts && (
        <form
          onSubmit={e => {
            e.preventDefault();
            e.stopPropagation();
            onSave({
              title: replaceWhitespaceCharacters(selectedChart.label),
              workSheetName: selectedChart.worksheetName,
              chartName: selectedChart.name
            });
            setSelectedChart({});
          }}
        >
          <label htmlFor="chart-to-push">Select a Chart</label>
          {isLoading ? (
            <Loading />
          ) : (
            <Select
              id="chart-to-push"
              characterLimit={34}
              onChange={event => {
                const { value } = event.target;
                setSelectedChart(
                  value ? charts.find(c => c.value === value) : ''
                );
                setStatusMessage({});
              }}
              options={charts}
              optionTestId="chart-option"
              placeholder="Please select"
              selectTestId="item-selector"
              value={selectedChart.value}
            />
          )}
          <InlineMessage text={statusMessage.text} type={statusMessage.type} />
          <div className="card-actions">
            <Button
              type="submit"
              data-testid="push-button"
              disabled={
                isLoading || !selectedChart.value || selectedChart.value === ''
              }
              className="button"
            >
              {submitText}
            </Button>
            {onCancel && (
              <Button
                type="button"
                data-testid="push-button-cancel"
                className="button button-link"
                onClick={onCancel}
                disabled={isLoading}
              >
                Cancel
              </Button>
            )}
          </div>
        </form>
      )}
    </div>
  );
};

export default ChartPicker;
