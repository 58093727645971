import {
  getLibraryItemsFromClipboard,
  getExcelLibraryItemsByGroup,
  getPptLibraryItemsByGroup,
  clearClipboard
} from '../../../common/services/clipboardService';
import { useNamedRanges } from '../../../common/utilities/excelUtilities';

const getChartItem = async (projectId, id, data) => {
  const contentType = 300;
  const request = {
    ...data,
    groupedByKey: data.driveItemId,
    excelLibraryItems: [
      {
        id,
        type: contentType,
        data: {
          formatType: 1,
          worksheetName: data.worksheetName,
          chartName: data.chartName
        },
        childrenData: []
      }
    ]
  };
  const item = await getExcelLibraryItemsByGroup(projectId, request);
  const rendered = item.fileContents[0].value;
  return { rendered, contentType, request };
};

const getNameRangeItem = async (projectId, id, data) => {
  const contentType = useNamedRanges ? 301 : 302;
  if (contentType === 301) {
    data = {
      ...data,
      namedRange: data.name
    };
  }
  const request = {
    ...data,
    groupedByKey: data.driveItemId,
    excelLibraryItems: [
      {
        id,
        type: contentType,
        data: {
          formatType: 1,
          worksheetName: data.worksheetName,
          cellRange: data.cellRange || data.name,
          ...(contentType === 301 && { namedRange: data.name })
        },
        childrenData: []
      }
    ]
  };
  const item = await getExcelLibraryItemsByGroup(projectId, request);
  const rendered = item.fileContents[0].value;
  return { rendered, contentType, request };
};

const getPowerPointItem = async (projectId, id, data) => {
  const contentType = 500;
  const request = {
    ...data,
    groupedByKey: data.driveItemId,
    pptLibraryItems: [
      {
        id,
        type: contentType,
        data: {
          formatType: 1,
          groupName: data.groupName,
          slideNumber: data.slideNumber
        },
        childrenData: []
      }
    ]
  };
  const item = await getPptLibraryItemsByGroup(projectId, request);
  const rendered = item.fileContents[0].value;
  return { rendered, contentType, request };
};

const handleEmptyClipboard = sharePointSiteUrl => {
  const { protocol, host } = window.location;
  const url = `${protocol}//${host}/dialogs/openSharepoint.html?url=${sharePointSiteUrl}`;
  window.Office.context.ui.displayDialogAsync(url, {
    height: 95,
    width: 49,
    displayInIframe: true,
    promptBeforeOpen: false
  });
};

const ckEditorMiniTableInsert = async (
  projectId,
  editor,
  sharePointSiteUrl
) => {
  try {
    let itemData = {};
    const response = await getLibraryItemsFromClipboard(projectId);
    if (response && response.data) {
      const { id, type, data } = response.data;
      switch (type) {
        case 300:
        case 'ExcelChart':
          itemData = await getChartItem(projectId, id, data);
          break;
        case 301:
        case 302:
        case 'ExcelNamedRange':
          itemData = await getNameRangeItem(projectId, id, data);
          break;
        case 500:
        case 'PowerpointGroup':
          itemData = await getPowerPointItem(projectId, id, data);
          break;
        default:
          break;
      }

      const libraryItem = {
        ...itemData,
        id
      };

      editor.execute('addLibraryItem', libraryItem);
      await clearClipboard(projectId, response.data);
    } else {
      handleEmptyClipboard(sharePointSiteUrl);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};

export default ckEditorMiniTableInsert;
