import { HttpClient } from '@pwc/http-client';
import authorizationService from './security/authorizationService';
import {
  getApplicationToken,
  getProjectToken
} from './security/tokenSessionStorage';

export const getProjectTokenBasedOnPath = path => {
  const projectId = path.startsWith('/')
    ? path.split('/')[2]
    : path.split('/')[1];

  return getProjectToken(projectId);
};

export const UserAccessTokenClient = new HttpClient(
  process.env.REACT_APP_USER_ACCESS_SERVICE
);

export const RefreshTokenClient = new HttpClient(
  process.env.REACT_APP_USER_ACCESS_SERVICE,
  async () => getApplicationToken()
);

export const MasterHttpClient = new HttpClient(
  process.env.REACT_APP_MASTER_API_BASE_PATH,
  async () => getApplicationToken()
);

export const ProjectHttpClient = new HttpClient(
  process.env.REACT_APP_PROJECT_API_BASE_PATH,
  async path => {
    authorizationService.updateProcessTime();
    return getProjectTokenBasedOnPath(path);
  }
);

export const StructuredAnalysisHttpClient = new HttpClient(
  process.env.REACT_APP_STRUCTURED_ANALYSIS_SERVICE,
  async path => {
    authorizationService.updateProcessTime();
    return getProjectTokenBasedOnPath(path);
  }
);

export const ProjectAuthClient = new HttpClient(
  process.env.REACT_APP_USER_ACCESS_SERVICE,
  async () => getApplicationToken()
);

export const ClipboardHttpClient = new HttpClient(
  process.env.REACT_APP_LIBRARY_ITEM_SERVICE,
  async path => {
    authorizationService.updateProcessTime();
    return getProjectTokenBasedOnPath(path);
  }
);

export const LibraryItemXlIframeClient = new HttpClient(
  process.env.REACT_APP_LIBRARY_ITEM_XL_IFRAME_SERVICE,
  async path => {
    authorizationService.updateProcessTime();
    return getProjectTokenBasedOnPath(path);
  }
);

export const LibraryItemsClipboardHttpClient = new HttpClient(
  process.env.REACT_APP_LIBRARY_ITEM_SERVICE,
  async path => {
    authorizationService.updateProcessTime();
    return getProjectTokenBasedOnPath(path);
  }
);

export const LibraryItemsExcelHttpClient = new HttpClient(
  process.env.REACT_APP_EXCEL_LIBRARY_ITEM_SERVICE,
  async path => {
    return getProjectTokenBasedOnPath(path);
  }
);

export const LibraryItemsPptHttpClient = new HttpClient(
  process.env.REACT_APP_PPT_LIBRARY_ITEM_SERVICE,
  async path => {
    return getProjectTokenBasedOnPath(path);
  }
);

export const ProjectStateManagementHttpClient = new HttpClient(
  process.env.REACT_APP_PROJECT_STATE_MANAGEMENT_API_BASE_PATH,
  async path => {
    authorizationService.updateProcessTime();
    return getProjectTokenBasedOnPath(path);
  }
);
