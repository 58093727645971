const getUserInitials = (givenName, familyName) => {
  const hasGivenName = givenName && givenName.trim().length !== 0;
  const hasFamilyName = familyName && familyName.trim().length !== 0;

  if (hasGivenName && hasFamilyName) return givenName[0] + familyName[0];

  if (hasGivenName) return givenName[0];

  if (hasFamilyName) return familyName[0];

  return '';
};

const loggedInUserMapper = {
  map: jwtToken => {
    if (!jwtToken) return {};

    return {
      initials: getUserInitials(jwtToken.given_name, jwtToken.family_name),
      givenName: jwtToken.given_name,
      familyName: jwtToken.family_name,
      name: `${jwtToken.given_name} ${jwtToken.family_name}`
    };
  }
};

export default loggedInUserMapper;
