import { v4 as uuidv4 } from 'uuid';
import { isValidUuid } from '../../../common/utilities/guidValidation';

const getRowIdCell = context => {
  const {
    rowIdColumnIndex,
    columnOffset,
    row,
    worksheet,
    excelContext
  } = context;

  const cell = worksheet.getCell(row, rowIdColumnIndex + columnOffset);
  cell.load('values');

  return excelContext
    .sync()
    .catch(() => {
      throw new Error('Error fetching Row ID');
    })
    .then(() => {
      let rowId = cell.values[0][0];
      if (rowId !== '' && !isValidUuid(rowId)) {
        throw new Error(
          'Invalid Row ID Value. Please remove value inside row ID to continue'
        );
      }

      if (rowId === '') {
        rowId = uuidv4();
      }

      return {
        ...context,
        rowId
      };
    });
};

export default getRowIdCell;
