import {
  getNamedItemsFromWorkbook,
  getWorksheets
} from './namedRangeServiceUtils';

const sanitizeName = name => name.replace(/\W/g, '');

export const getNamedRanges = async context => {
  const worksheetNames = await getWorksheets(context);
  const sanitizedWorksheetNames = worksheetNames.map(sanitizeName);
  const items = await getNamedItemsFromWorkbook(context);
  return items
    .filter(item => item.type === 'Range')
    .filter(item => {
      const { value } = item;
      const lastOccuranceOfBang = value.lastIndexOf('!');
      const worksheetName = value.substring(0, lastOccuranceOfBang);
      const rangeAddress = value.substring(lastOccuranceOfBang + 1);
      const sanitizedWorksheetName = sanitizeName(worksheetName);
      const isRange = !!rangeAddress.match(':');

      return (
        sanitizedWorksheetNames.indexOf(sanitizedWorksheetName) > -1 && isRange
      );
    })
    .map(item => ({ name: item.name }));
};
