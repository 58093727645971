import React, { useEffect, useState } from 'react';
import qs from 'query-string';
import { Redirect } from 'react-router';
import authorizationService from '../../services/security/authorizationService';
import authenticationUrlBuilder from '../../services/security/authenticationUrlBuilder';
import locationService from '../../services/locationService';
import userTokenService from '../../services/security/userTokenService';

const Login = ({ host, addinNewVersion = true }) => {
  const { code } = qs.parse(window.location.search);
  const [hostType] = useState(host);
  const [hasValidToken, setHasValidToken] = useState(
    authorizationService.hasValidToken()
  );

  // eslint-disable-next-line
  const getRedirectUrl = () => {
    let pathname = '/content';

    if (addinNewVersion && hostType) {
      switch (hostType) {
        case window.Office.HostType.Excel:
          pathname = '/excel';
          break;
        case window.Office.HostType.PowerPoint:
          pathname = '/power-point';
          break;
        default:
          pathname = '/content';
      }
    }

    return {
      pathname
    };
  };

  useEffect(() => {
    if (code) {
      userTokenService
        .getTokens(code)
        .then(tokenSetResponse => {
          authorizationService.setTokens(tokenSetResponse);
          setHasValidToken(authorizationService.hasValidToken());
        })
        .catch(() => {
          locationService.redirect(authenticationUrlBuilder.build());
        });
    }
  }, [code]);

  if (hasValidToken) {
    return <Redirect to={getRedirectUrl()} />;
  }

  if (!code) {
    authenticationUrlBuilder.build(addinNewVersion).then(authenticationUrl => {
      locationService.redirect(authenticationUrl);
    });
  }

  return null;
};

export default React.memo(Login);
