const COLORS = {
  disabledColor: '#6e6e6e',
  disabledBorder: '#bbbbbb'
};

export const getMuiTheme = () => ({
  typography: {
    fontFamily: ['PwC Helvetica Neue', 'helvetica', 'arial', 'sans-serif'].join(
      ','
    )
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#bd4301' // darker orange
    },
    error: {
      main: '#C52A1A' // red
    },
    action: {
      active: '#bd4301', // darker orange
      disabled: COLORS.disabledColor
    },
    success: {
      main: '#1E8629' // light green
    }
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          lineHeight: '1.5rem'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          '&:hover': {
            textDecoration: 'underline',
            backgroundColor: 'transparent'
          },
          '&.Mui-disabled': {
            borderColor: COLORS.disabledBorder
          }
        },
        outlined: ({ theme }) => ({
          borderRadius: '2px',
          borderColor: theme.palette.primary.main
        })
      }
    }
  }
});
