import React, { useEffect, useState } from 'react';
import { Button, Select } from '@pwc/pwc-deals-ui';
import { getNamedRanges } from './namedRangeService';
import InlineMessage from '../InlineMessage/InlineMessage';
import './NamedRangePicker.scss';
import Loading from '../Loading/Loading';
import { useActiveWorksheet } from '../../services/worksheetService';

const NamedRangePicker = ({
  onCancel,
  onSave,
  excelContext,
  submitText,
  statusMessage,
  setStatusMessage,
  pickerTitle,
  isLoading
}) => {
  const [namedRanges, setNamedRanges] = useState();
  const [selectedNamedRange, setSelectedNamedRange] = useState('');
  const activeWorksheet = useActiveWorksheet(excelContext);

  useEffect(() => {
    if (activeWorksheet) {
      (async () => {
        const result = await getNamedRanges(excelContext);

        if (result) setNamedRanges(result);
      })();
    }
  }, [excelContext, activeWorksheet]);

  const options = namedRanges
    ? namedRanges.map(namedRange => namedRange.name)
    : [];

  return (
    <div className="card named-range-picker">
      <h3 className="card-title">{pickerTitle}</h3>
      {!namedRanges && <Loading />}
      {namedRanges && (
        <form
          data-testid="named-range-form"
          onSubmit={e => {
            e.preventDefault();
            e.stopPropagation();
            onSave({ name: selectedNamedRange.name });
            setSelectedNamedRange('');
          }}
        >
          <label htmlFor="named-range-select">Select a Named Range</label>
          {isLoading ? (
            <Loading />
          ) : (
            <Select
              id="named-range-select"
              characterLimit={34}
              onChange={event => {
                const { value } = event.target;
                setSelectedNamedRange(
                  value
                    ? namedRanges.find(namedRange => namedRange.name === value)
                    : ''
                );
                setStatusMessage({});
              }}
              options={options}
              optionTestId="named-range-option"
              selectTestId="named-range-select"
              placeholder="Please select"
              value={selectedNamedRange.name}
            />
          )}
          <InlineMessage text={statusMessage.text} type={statusMessage.type} />
          <div className="card-actions">
            <Button
              type="submit"
              data-testid="named-range-push-button"
              className="button"
              disabled={
                isLoading ||
                !selectedNamedRange ||
                selectedNamedRange.name === ''
              }
            >
              {submitText}
            </Button>
            {onCancel && (
              <Button
                type="button"
                data-testid="named-range-push-button-cancel"
                className="button button-link"
                onClick={onCancel}
                disabled={isLoading}
              >
                Cancel
              </Button>
            )}
          </div>
        </form>
      )}
    </div>
  );
};

export default NamedRangePicker;
