import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { history } from './reduxStore';

const reactPlugin = new ReactPlugin();
const appInsights = process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY
  ? new ApplicationInsights({
      config: {
        instrumentationKey:
          process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history }
        }
      }
    })
  : null;

if (appInsights) {
  appInsights.loadAppInsights();
  appInsights.trackPageView();
}

export { reactPlugin, appInsights };
