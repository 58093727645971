import React, { useState } from 'react';
import { postChart } from '../../common/services/libraryItemsService';
import ChartPicker from '../../common/components/ChartPicker/ChartPicker';
import platformName from '../../common/utilities/get.platform.name';

const ChartPickerContainer = ({ project, fullPath, excelContext }) => {
  const [statusMessage, setStatusMessage] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleSaveChart = params => {
    setIsLoading(true);
    postChart(project.id, {
      ...params,
      sharePointItemUrl: fullPath
    })
      .then(() => {
        setStatusMessage({
          text: `Chart successfully saved in ${platformName}!`,
          type: 'success'
        });
      })
      .catch(() => {
        setStatusMessage({
          text: 'An error occurred while saving your chart. Please try again.',
          type: 'failure'
        });
      })
      .then(() => setIsLoading(false));
  };

  return (
    <ChartPicker
      onSave={handleSaveChart}
      submitText={`Push to ${platformName}`}
      statusMessage={statusMessage}
      pickerTitle="Chart Ingestion"
      excelContext={excelContext}
      setStatusMessage={setStatusMessage}
      isLoading={isLoading}
    />
  );
};

export default ChartPickerContainer;
