import { useState, useEffect } from 'react';

export const getActiveWorksheet = async excelContext => {
  const sheet = excelContext.workbook.worksheets.getActiveWorksheet();
  sheet.load('name');

  await excelContext.sync();

  return sheet;
};

export const useActiveWorksheet = excelContext => {
  const [activeWorksheet, setActiveWorksheet] = useState(null);

  useEffect(() => {
    (async () => {
      const result = await getActiveWorksheet(excelContext);

      if (result) setActiveWorksheet(result);
    })();
  }, [excelContext]);

  return activeWorksheet;
};
