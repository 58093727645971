import decode from 'jwt-decode';
import {
  getApplicationToken,
  removeApplicationToken,
  saveApplicationToken,
  saveProjectToken,
  saveProcessTime,
  getProcessTime
} from './tokenSessionStorage';

/**
 *
 * @returns decoded jwt or NULL
 */
const getDecodedApplicationToken = () => {
  const jwtTokenValue = getApplicationToken();

  if (!jwtTokenValue) {
    return null;
  }

  return decode(jwtTokenValue);
};

const secondsFromExpUntilLastProcessTime = () => {
  const jwtToken = getDecodedApplicationToken();
  const processTime = getProcessTime();

  if (!jwtToken || !processTime) {
    return 0;
  }
  return jwtToken.exp - parseInt(processTime, 10);
};

const hasValidToken = () => {
  const jwtToken = getDecodedApplicationToken();

  if (jwtToken && jwtToken.exp > Date.now() / 1000) {
    return true;
  }

  removeApplicationToken();

  return false;
};

const setTokens = tokenSet => {
  saveApplicationToken(tokenSet.applicationToken);
  if (tokenSet.projectTokens) {
    tokenSet.projectTokens.forEach(projectToken => {
      saveProjectToken(projectToken.projectId, projectToken.token);
    });
  }
};

// This is used to track active use, like rich text changes and api calls.
const updateProcessTime = () => {
  const time = Date.now() / 1000;
  saveProcessTime(time);
};

const authorizationService = {
  secondsFromExpUntilLastProcessTime,
  hasValidToken,
  setTokens,
  getDecodedApplicationToken,
  updateProcessTime
};

export default authorizationService;
