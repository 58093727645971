import { StructuredAnalysisHttpClient } from '../../../common/services/apiClients';

const setRichText = context => {
  const {
    libraryItemId,
    longDescription,
    projectId,
    rowId,
    registeredLinks
  } = context;

  const exists = Boolean(libraryItemId);

  let path = `projects/${projectId}/LibraryItems/richText`;
  if (exists) path += `/${libraryItemId}`;
  const method = exists ? 'put' : 'post';

  const body = {
    name: rowId,
    richTextData: longDescription,
    childItemIds: registeredLinks
  };

  return StructuredAnalysisHttpClient[method]({
    relativePath: path,
    data: body
  })
    .then(({ data }) => ({
      ...context,
      exists,
      libraryItemId: data.data,
      name: rowId
    }))
    .catch(e => {
      throw new Error(e.message);
    });
};

export default setRichText;
