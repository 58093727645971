import React from 'react';

const supportLink =
  'https://wwwpwcnetwork.pwc.myshn.net/hub?id=sc_cat_item&sys_id=ff2977811b357010bdec7bb5464bcb0f';

const SupportTicketLink = () => {
  return (
    <a
      href={supportLink}
      target="_blank"
      rel="noopener noreferrer"
      className="external-source-link"
      style={{ textDecoration: 'underline' }}
    >
      create a support ticket here
    </a>
  );
};

export default SupportTicketLink;
