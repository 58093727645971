const getShortDescriptionCell = context => {
  const {
    shortDescriptionColumnIndex,
    columnOffset,
    row,
    worksheet,
    excelContext
  } = context;

  const cell = worksheet.getCell(
    row,
    shortDescriptionColumnIndex + columnOffset
  );
  cell.load('values');

  return excelContext
    .sync()
    .catch(() => {
      throw new Error('Error fetching Short Description');
    })
    .then(() => {
      const shortDescription = cell.values[0][0];

      if (shortDescription === '') {
        throw new Error('Please fill out the Short Description');
      }

      return {
        ...context,
        shortDescription
      };
    });
};

export default getShortDescriptionCell;
