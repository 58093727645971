import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { createMemoryHistory } from 'history';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './app/ApplicationInsights';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import { store } from './app/reduxStore';
import jobRunner from './common/jobs/job.runner';

jobRunner.start();

window.Office.onReady(info => {
  const history = createMemoryHistory();

  ReactDOM.render(
    <Provider store={store}>
      <AppInsightsContext.Provider value={reactPlugin}>
        <Router history={history}>
          <App info={info} />
        </Router>
      </AppInsightsContext.Provider>
    </Provider>,
    document.getElementById('root')
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
