import React from 'react';
import { Icon } from '@pwc/pwc-deals-ui';
import SupportTicketLink from '../SupportTicketLink';
import './CopyErrorMessage.scss';

const defaultErrorMessage =
  "There's a problem adding the object. Please try again later.";

const CopyErrorMessage = ({ message = defaultErrorMessage }) => {
  return (
    <div className="copy-error-message__success error">
      <div className="copy-error-message__success__icon-wrapper error">
        <Icon
          name="alert"
          className="fill icon-error input-error-icon"
          data-testid="copy-error-message__error__icon"
          width={18}
        />
      </div>
      <p className="error">
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        {message} If the issue continues, please <SupportTicketLink />
      </p>
    </div>
  );
};

export default CopyErrorMessage;
