import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';

import { postDriveDetails } from '../../common/services/clipboardService';
import { ingestDynamicContent } from '../../common/services/libraryItemsService';

import { getExcelCellRangeOrChart } from './utils';
import { Loader } from '../../common/components/Loader';
import CopyErrorMessage from '../../common/components/CopyErrorMessage/CopyErrorMessage';
import InfoBox from '../components/InfoBox';

export const DynamicObject = ({
  excelContext,
  sharePointItemUrl,
  projectId,
  drive,
  fileName,
  enableDynamicIngestion
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [dynamicIngestSuccess, setDynamicIngestSuccess] = useState(false);

  const [sourcedoc, setSourcedoc] = useState();
  const [cellRangeToIngest, setCellRangeToIngest] = useState();
  const [docData, setDocData] = useState();

  const getPivotTablesInSelectedRange = async () => {
    setCellRangeToIngest(undefined);
    setDocData(undefined);
    try {
      await window.Excel.run(async context => {
        const activeRange = context.workbook.getSelectedRange();

        // Get all the PivotTables that intersect with this range.
        const partiallyContainedPivotTables = activeRange.getPivotTables();
        // Get all the PivotTables that are completely contained within this range.
        const fullyContainedPivotTables = activeRange.getPivotTables(true);

        partiallyContainedPivotTables.load('name');
        fullyContainedPivotTables.load('name');
        await activeRange.load('address');
        await context.sync();

        const cleanRange = encodeURIComponent(activeRange.address);

        setCellRangeToIngest(cleanRange);
        // await context.sync();

        getExcelCellRangeOrChart(excelContext).then(data => {
          const newDocData = {
            workSheetName: data.sheet.name,
            selectedRange: data.range.address,
            sheetUniqueId: data.sheet.id,
            sharePointItemUrl
          };
          setDocData(newDocData);

          return Promise.resolve();
        });

        // Display the names in the console.
        // console.warn('PivotTables in the current range:');
        // partiallyContainedPivotTables.items.forEach(pivotTable => {
        //   console.warn(`\t${pivotTable.name}`);
        //   // setHasPivotInRange(true);
        //   // setHasCompletePivotInRange(false);
        // });
        // console.warn('PivotTables completely contained in the current range:');
        // fullyContainedPivotTables.items.forEach(pivotTable => {
        //   // setHasPivotInRange(true);
        //   // setHasCompletePivotInRange(true);
        //   console.warn(`\t${pivotTable.name}`);
        // });

        // await context.workbook.worksheets.load('items');
        // await context.sync();
        // const { items } = context.workbook.worksheets;
        // console.warn('worksheets', items);
        // items.forEach(item => {
        //   console.warn('item', item, 'id', item.id);
        // });
      });
    } catch (catchError) {
      const message = 'Error while getting PivotTablesInSelectedRange';
      console.error(message, catchError); // eslint-disable-line
      setError(message);
      setLoading(false);
    }
  };

  const fetchSourcedoc = async () => {
    setSourcedoc(undefined);
    try {
      const response = await postDriveDetails(projectId, {
        SharePointItemUrl: sharePointItemUrl
      });
      const webUrl = response.data.data.driveItemWebUrl;

      const query = webUrl.split('?')[1];
      const params = new Proxy(new URLSearchParams(query), {
        get: (searchParams, prop) => searchParams.get(prop)
      });
      setSourcedoc(params.sourcedoc);
    } catch (catchError) {
      const message = 'Error while fetching sourcedoc';
      console.error(catchError); // eslint-disable-line
      setError(message);
      setLoading(false);
    }
  };

  const handleDynamicObjectClick = () => {
    setDynamicIngestSuccess(false);
    setError(undefined);
    setLoading(true);
    fetchSourcedoc();
    getPivotTablesInSelectedRange();
  };

  useEffect(() => {
    if (
      drive &&
      sourcedoc &&
      cellRangeToIngest &&
      docData &&
      enableDynamicIngestion
    ) {
      const getEmbedUrl = () => {
        let activeCell = '';
        if (cellRangeToIngest && cellRangeToIngest.indexOf('!') > -1) {
          activeCell = `${cellRangeToIngest.split('!')[0]}!A1`;
        } else {
          activeCell = `${cellRangeToIngest}!A1`;
        }
        /**
         * cell range embedded url:
         *  return `https://${drive}/_layouts/15/Doc.aspx?sourcedoc=${sourcedoc}&action=embedview&wdHideGridlines=True&wdHideHeaders=True&Item=${cellRangeToIngest}&wdInConfigurator=True&edesNext=false&resen=false`;
         */
        return `https://${drive}/_layouts/15/Doc.aspx?sourcedoc=${sourcedoc}&action=embedview&wdHideGridlines=True&wdHideHeaders=True&ActiveCell=${activeCell}&wdInConfigurator=True&edesNext=false&resen=false&wdHideSheetTabs=True`;
      };

      const params = {
        embeddedUrl: getEmbedUrl(),
        name: fileName,
        uniqueId: docData.sheetUniqueId,
        cellRange: docData.selectedRange,
        workSheetName: docData.workSheetName,
        sharePointItemUrl: docData.sharePointItemUrl
      };
      const processDynamicCellRange = async () => {
        try {
          await ingestDynamicContent(projectId, params);
          setDynamicIngestSuccess(true);
          setLoading(false);
        } catch (catchError) {
          const message = `There's a problem adding the worksheet. Please try again later.`;
          console.error('Error during processDynamicCellRange', catchError); // eslint-disable-line
          setError(message);
          setLoading(false);
        }
      };
      processDynamicCellRange();
    }
  }, [
    drive,
    sourcedoc,
    cellRangeToIngest,
    docData,
    projectId,
    enableDynamicIngestion
  ]);

  return (
    <>
      <Button
        disabled={loading || !enableDynamicIngestion}
        variant="outlined"
        onClick={handleDynamicObjectClick}
        sx={{
          height: '2.375rem'
        }}
      >
        {loading ? (
          <Loader size={20} color={loading ? 'inherit' : 'primary'} />
        ) : (
          'Add current worksheet'
        )}
      </Button>
      {dynamicIngestSuccess && (
        <Box
          sx={{
            mt: '1rem'
          }}
        >
          <InfoBox title="Worksheet now available in Junction" />
        </Box>
      )}
      {error && <CopyErrorMessage message={error} />}
    </>
  );
};
