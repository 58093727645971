import {
  postChart,
  getLibraryItemData,
  postNamedRange
} from '../../../common/services/libraryItemsService';

export const CHART = 'Chart';
export const TABLE = 'Table';

export const retrieveLibraryItem = (
  projectId,
  sharePointItemUrl,
  params,
  itemType
) => {
  const serviceMethod = itemType === TABLE ? postNamedRange : postChart;

  return serviceMethod(projectId, {
    ...params,
    sharePointItemUrl
  }).then(response => {
    return getLibraryItemData(projectId, response.data).then(result => ({
      id: response.data,
      rendered: result
    }));
  });
};
