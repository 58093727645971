export const getSharepointSite = fullPath => {
  let sharepointSite;
  let parsedUrl;
  try {
    parsedUrl = new URL(fullPath);
    sharepointSite = parsedUrl.hostname;
  } catch (e) {
    return null;
  }

  // If document isn't in the root/default site, extract the sub-site
  const urlSegments = parsedUrl.pathname.split('/');
  if (parsedUrl && urlSegments.length > 2) {
    sharepointSite += `/${urlSegments[1]}/${urlSegments[2]}`;
  }

  return sharepointSite;
};
