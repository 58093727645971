export const convertColumnIndexToCharacter = (columnIndex, columnOffset) => {
  // NOTE: that is was extracted out and will work to column "Z",
  // but after that will need (IE, "AA", "AAA", etc) to be refactored
  let characterIndex = 0;

  // startingCharIndex for Alphabet
  const startingIndex = 65;
  if (columnIndex >= 0) {
    characterIndex = columnIndex + startingIndex + columnOffset;
  }

  return String.fromCharCode(characterIndex);
};

export const isWorksheetIngestionEnabled =
  (process.env.REACT_APP_ENABLE_WORKSHEET_INGESTION || '')
    .trim()
    .replace(/\W/g, '') === 'true';

export const useNamedRanges =
  (process.env.REACT_APP_ENABLE_NAMED_RANGES || '')
    .trim()
    .replace(/\W/g, '') === 'true';
