export const handleChartActivated = async (
  event,
  excelContext,
  charts,
  setSelectedChart,
  setStatusMessage
) => {
  await excelContext.sync();

  const { chartId } = event;
  const activatedChart = charts.find(chart => chart.id === chartId);

  setSelectedChart(activatedChart);
  setStatusMessage({});
};
