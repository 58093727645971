import React from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from '@pwc/pwc-deals-ui';

const Navigation = () => (
  <nav className="tabs">
    <NavLink
      className="tabs-tab"
      to="/content"
      data-testid="content-link"
      activeClassName="tabs-tab--active"
    >
      <div className="tabs-tabcontent tabs-tabcontent--ingestion">
        <Icon name="ingestion-upload" className="icon" width={22} />
        <span>Ingestion</span>
      </div>
    </NavLink>
    <NavLink
      className="tabs-tab"
      to="/rich-text"
      data-testid="rich-text-link"
      activeClassName="tabs-tab--active"
    >
      <div className="tabs-tabcontent tabs-tabcontent--richtext">
        <Icon name="richtexteditor" className="icon" width={22} />
        <span>Rich Text</span>
      </div>
    </NavLink>
  </nav>
);

export default Navigation;
