import React from 'react';
import { Box, Typography } from '@mui/material';
import { Icon } from '@pwc/pwc-deals-ui';
import classNames from 'classnames';

const getColorIconCoding = ({ isWarning, isError }) => {
  if (isError) {
    return {
      iconName: 'alert',
      iconColorClass: 'icon-error',
      color: 'var(--color-error)'
    };
  }
  if (isWarning) {
    return {
      iconName: 'information',
      iconColorClass: 'icon-yellow',
      color: undefined
    };
  }
  return {
    iconName: 'check',
    iconColorClass: 'icon-green',
    color: undefined
  };
};

const InfoBox = ({ title, description, isWarning, isError }) => {
  const { iconName, iconColorClass, color } = getColorIconCoding({
    isWarning,
    isError
  });

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        mb: '1rem',
        columnGap: '0.5rem'
      }}
    >
      <Box sx={{ display: 'flex', mt: '0.25rem' }}>
        <Icon
          name={iconName}
          width="16"
          className={classNames('fill', iconColorClass)}
        />
      </Box>
      <Box color={color}>
        {!!title && (
          <Typography sx={{ fontWeight: 'bold', mb: '0.25rem' }}>
            {title}
          </Typography>
        )}
        {!!description && <Typography>{description}</Typography>}
      </Box>
    </Box>
  );
};

export default InfoBox;
