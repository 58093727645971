import userTokenService from '../services/security/userTokenService';
import locationService from '../services/locationService';
import authorizationService from '../services/security/authorizationService';

export const tokenRefreshJob = async () => {
  const expirationCutoffInSeconds = 13 * 60;

  if (authorizationService.hasValidToken()) {
    const secondsFromExpUntilLastProcessTime = authorizationService.secondsFromExpUntilLastProcessTime();
    const hasExpired =
      secondsFromExpUntilLastProcessTime < expirationCutoffInSeconds;

    if (hasExpired) {
      const refreshedTokenSet = await userTokenService.refreshTokens();
      authorizationService.setTokens(refreshedTokenSet);
    }
  } else {
    locationService.redirect(
      `${window.location.protocol}//${window.location.host}`
    );
  }
};
