const HIDDEN_TAB_NAME = 'PwC-VeryHiddenRenames';

const tabExist = async context => {
  const sheets = context.workbook.worksheets;
  sheets.load('items/name');
  await context.sync();
  const found = sheets.items.find(sh => sh.name === HIDDEN_TAB_NAME);
  return !!found;
};

const saveSheetReference = async (context, row = 2) => {
  const activeSheet = context.workbook.worksheets.getActiveWorksheet();
  activeSheet.load('name, address');
  await context.sync();

  const historySheet = context.workbook.worksheets.getItem(HIDDEN_TAB_NAME);
  const tabDetailsRange = historySheet.getRange(`A${row}:B${row}`);
  tabDetailsRange.values = [[activeSheet.id, `='${activeSheet.name}'!A1`]];
  await context.sync();
  return activeSheet.id;
};

const createHiddenTab = async context => {
  const { NODE_ENV } = process.env;
  const isDev = NODE_ENV === 'development';
  const sheets = context.workbook.worksheets;

  const hiddenSheet = sheets.add(HIDDEN_TAB_NAME);
  hiddenSheet.load('name, position');

  const headerRange = hiddenSheet.getRange('A1:B1');
  headerRange.values = [['UniqueId', 'SheetReference']];

  const { visible, veryHidden } = window.Excel.SheetVisibility;
  hiddenSheet.visibility = isDev ? visible : veryHidden;

  const uniqueId = await saveSheetReference(context);
  return uniqueId;
};

const getSheetData = async context => {
  const sheet = context.workbook.worksheets.getItem(HIDDEN_TAB_NAME);
  const range = sheet.getUsedRange();
  range.load('values');

  await context.sync();

  const data = [];
  range.values.forEach((row, index) => {
    if (index > 0) {
      data.push({ id: row[0] });
    }
  });
  return data;
};

const updateHiddenTab = async context => {
  const activeSheet = context.workbook.worksheets.getActiveWorksheet();
  activeSheet.load('name, id');
  await context.sync();

  const { id } = activeSheet;
  const data = await getSheetData(context);
  const found = data.find(d => d.id === id);
  if (!found) {
    await saveSheetReference(context, data.length + 2);
  }
  return id;
};

const updateVeryHiddenSheet = async context => {
  let uniqueId;
  const exist = await tabExist(context);
  if (!exist) {
    uniqueId = await createHiddenTab(context);
  } else {
    uniqueId = await updateHiddenTab(context);
  }
  return uniqueId;
};

export default updateVeryHiddenSheet;
