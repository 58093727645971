export const getNamedItemsFromWorkbook = async excelContext => {
  const { workbook } = excelContext;
  workbook.load('names');
  await excelContext.sync();
  const { names } = workbook;
  return names.items;
};

export const getWorksheets = async excelContext => {
  const { worksheets } = excelContext.workbook;
  worksheets.load('items');
  await excelContext.sync();
  return worksheets.items.map(w => w.name);
};
