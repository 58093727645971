import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';
import Loading from '../../common/components/Loading/Loading';
import InlineMessage from '../../common/components/InlineMessage/InlineMessage';
import { Card } from '../../common/components/Card/Card';

const ActionPanel = ({
  name,
  title,
  descriptionText,
  buttonText,
  buttonTestId,
  handleOnClick,
  needsVerification,
  verificationMessage,
  isProcessing,
  statusMessage,
  supplementalMessage,
  tooltipLabel,
  tooltipLink
}) => {
  const [verifyConfirm, setVerifyConfirm] = useState(false);

  const handleCancel = () => {
    setVerifyConfirm(false);
  };

  const handleConfirm = e => {
    handleOnClick(e);
    setVerifyConfirm(false);
  };

  return (
    <Card
      title={title}
      cardTestId={`${name}-card`}
      titleTestId={`${name}-title`}
      tooltipLabel={tooltipLabel}
      tooltipLink={tooltipLink}
    >
      <Typography data-testid={`${name}-description`}>
        {descriptionText}
      </Typography>
      <InlineMessage
        text={supplementalMessage.text}
        type={supplementalMessage.type}
      />
      <InlineMessage text={statusMessage.text} type={statusMessage.type} />

      {isProcessing && <Loading />}

      {needsVerification && verifyConfirm ? (
        <>
          <InlineMessage text={verificationMessage} type="failure" />
          <div className="card-actions" data-testid={`${name}-card-actions`}>
            <Button
              variant="outlined"
              type="button"
              className="button"
              onClick={handleConfirm}
              data-testid="confirm-card-action"
            >
              Yes, upload
            </Button>
            <Button
              variant="outlined"
              type="button"
              className="button button-link"
              onClick={handleCancel}
              data-testid="cancel-card-action"
            >
              Cancel
            </Button>
          </div>
        </>
      ) : (
        <div className="card-actions" data-testid={`${name}-card-actions`}>
          <Button
            variant="outlined"
            type="button"
            className="button"
            onClick={e => {
              if (needsVerification) {
                setVerifyConfirm(true);
              } else {
                handleOnClick(e);
              }
            }}
            disabled={isProcessing}
            data-testid={buttonTestId}
          >
            {buttonText}
          </Button>
        </div>
      )}
    </Card>
  );
};

ActionPanel.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  descriptionText: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  buttonTestId: PropTypes.string.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  needsVerification: PropTypes.bool,
  verificationMessage: PropTypes.string,
  isProcessing: PropTypes.bool,
  statusMessage: PropTypes.shape({
    text: PropTypes.string,
    type: PropTypes.string
  }),
  supplementalMessage: PropTypes.shape({
    text: PropTypes.string,
    type: PropTypes.string
  })
};

ActionPanel.defaultProps = {
  title: '',
  descriptionText: '',
  isProcessing: false,
  statusMessage: { text: '', type: '' },
  supplementalMessage: { text: '', type: '' },
  needsVerification: false,
  verificationMessage: ''
};

export default ActionPanel;
