import React from 'react';
import { useDispatch } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { setLoggedInUserThunk } from '../../../features/loggedInUser/setLoggedInUserThunk';

const PrivateRoute = ({ isAuthorized, component: Component, ...rest }) => {
  const dispatch = useDispatch();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (isAuthorized()) {
          dispatch(setLoggedInUserThunk());
          return <Component />;
        }
        return (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location }
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
