import React from 'react';
import PropTypes from 'prop-types';

const ProjectTitle = ({ project }) => {
  return <h2 data-testid="project-name">{project.name}</h2>;
};

ProjectTitle.defaultProps = {
  project: {}
};

ProjectTitle.propTypes = {
  project: PropTypes.shape({
    name: PropTypes.string
  })
};

export default ProjectTitle;
