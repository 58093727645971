import { StructuredAnalysisHttpClient } from '../../../common/services/apiClients';

const getLibraryItemLink = async (projectId, documentId, rowId) => {
  const path = `projects/${projectId}/structuredAnalysisDocuments/${documentId}/libraryItems`;
  try {
    const result = await StructuredAnalysisHttpClient.get({
      relativePath: path
    });
    const links = result.data.data.libraryItemLinks;
    return links.find(link => link.textKey === rowId);
  } catch (e) {
    throw new Error(
      `failed to get library item link for document id ${documentId}`
    );
  }
};

export default getLibraryItemLink;
