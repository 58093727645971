import React from 'react';
import classNames from 'classnames';
import { Box, Typography } from '@mui/material';
import { TooltipPopup } from '../TooltipPopup';

export const Card = ({
  title,
  children,
  titleTestId,
  cardTestId,
  className,
  tooltipLabel,
  tooltipLink,
  alternativeTitleColor,
  ...props
}) => {
  return (
    <Box
      className={classNames('card', className)}
      {...props}
      sx={{
        bgcolor: 'white',
        boxShadow: '0px 1px 1px rgba(0,0,0,0.16)',
        borderRadius: '2px',
        p: '1rem',
        mb: '1rem'
      }}
      data-testid={cardTestId}
    >
      <Box
        sx={{
          display: 'flex',
          direction: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          lineHeight: '1.688rem',
          mb: '0.375rem'
        }}
      >
        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: '1.125rem',
            color: alternativeTitleColor || undefined
          }}
          data-testid={titleTestId}
        >
          {title}
        </Typography>
        {tooltipLabel && tooltipLink ? (
          <TooltipPopup tooltipLabel={tooltipLabel} tooltipLink={tooltipLink} />
        ) : null}
      </Box>
      {children}
    </Box>
  );
};
