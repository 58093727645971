import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Header from '../../../common/components/layout/Header';
import ChartPicker from '../../../common/components/ChartPicker/ChartPicker';
import { getFullPath } from '../../workbook/workbook';
import { getProjectData } from '../../project/project';
import { retrieveLibraryItem, TABLE, CHART } from './insertLibraryItemService';

import './InsertLibraryItem.scss';
import ItemTypeSelect from './ItemTypeSelect';
import NamedRangePicker from '../../../common/components/NamedRangePicker/NamedRangePicker';

const InsertLibraryItem = ({ onCancel, onSave, excelContext }) => {
  const fullPath = useSelector(getFullPath);
  const project = useSelector(getProjectData);
  const [isLoading, setIsLoading] = useState(false);
  const [libraryItemType, setLibraryItemType] = useState(null);

  const [statusMessage, setStatusMessage] = useState({});

  const handleSelectLibraryItem = (params, itemType) => {
    setIsLoading(true);
    retrieveLibraryItem(project.id, fullPath, params, itemType)
      .then(result => {
        onSave(result);
      })
      .catch(() => {
        setStatusMessage({
          text:
            'An error occured while inserting your library item. Please try agian.',
          type: 'failure'
        });
      })
      .then(() => setIsLoading(false));
  };

  const libraryItemSelect = () => {
    if (libraryItemType === TABLE) {
      return (
        <NamedRangePicker
          onCancel={onCancel}
          onSave={params => handleSelectLibraryItem(params, TABLE)}
          pickerTitle="Insert Library Item"
          submitText="Insert Table"
          statusMessage={statusMessage}
          setStatusMessage={setStatusMessage}
          excelContext={excelContext}
          isLoading={isLoading}
        />
      );
    }
    return (
      <ChartPicker
        onCancel={onCancel}
        onSave={params => handleSelectLibraryItem(params, CHART)}
        pickerTitle="Insert Library Item"
        submitText="Insert Chart"
        statusMessage={statusMessage}
        setStatusMessage={setStatusMessage}
        excelContext={excelContext}
        isLoading={isLoading}
      />
    );
  };

  return (
    <div className="insert-library-item">
      <Header />
      <main>
        {libraryItemType ? (
          libraryItemSelect()
        ) : (
          <ItemTypeSelect
            options={[TABLE, CHART]}
            onSave={setLibraryItemType}
            onCancel={onCancel}
          />
        )}
      </main>
    </div>
  );
};

export default InsertLibraryItem;
