import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import { Card } from '../../common/components/Card/Card';
import ContentLayout from '../../common/components/layout/ContentLayout';
import {
  postChart,
  postCellRange
} from '../../common/services/clipboardService';
import {
  getFullPath,
  setWorkbookThunk,
  getWorkbookName
} from '../../features/workbook/workbook';
import {
  getProjectData,
  setProjectThunk,
  getProjectConnected
} from '../../features/project/project';
import { useExcelContext } from '../../common/components/Excel/useExcelContext';

import {
  getExcelCellRangeOrChart,
  addChartEventListeners,
  getExcelSheetName,
  selectRange
} from './utils';

import updateVeryHiddenSheet from './updateVeryHiddenSheet';
import CopyErrorMessage from '../../common/components/CopyErrorMessage/CopyErrorMessage';

import { getSharepointSite } from '../../features/project/sharepointService';
import { getProjectSettingsById } from '../../common/services/projectManagementService';

import '@pwc/pwc-deals-ui/build/styles/main.css';
import '../../styles/main.scss';
import { DynamicObject } from './DynamicObject';
import { Loader } from '../../common/components/Loader';
import { isWorksheetIngestionEnabled } from '../../common/utilities/excelUtilities';
import { useFileSize } from '../../common/utilities/useFileSize';
import FileSizeWarning from './components/FileSizeWarning';
import InfoBox from '../components/InfoBox';

export const ExcelApp = () => {
  const dispatch = useDispatch();
  const excelContext = useExcelContext();
  const projectConnected = useSelector(getProjectConnected);
  const {
    fileSizeInBytes,
    isLoading: isLoadingFileSize,
    hasError: hasErrorFileSize
  } = useFileSize();

  const [enableDynamicIngestion, setEnableDynamicIngestion] = useState(true);

  const sharePointItemUrl = useSelector(getFullPath);
  const title = useSelector(getWorkbookName);
  const project = useSelector(getProjectData);
  const projectId = project && project.id;

  const initChartsForSheetName = useRef({});

  const [initialLoading, setInitialLoading] = useState(true);
  const [isAdding, setIsAdding] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const drive = getSharepointSite(sharePointItemUrl);

  const handleSuccess = () => {
    setIsAdding(false);
    setIsSuccess(true);
    setIsError(false);
  };

  const handleError = () => {
    setIsAdding(false);
    setIsError(true);
    setInitialLoading(false);
  };

  const handleAddStaticObjectClick = useCallback(async () => {
    setIsAdding(true);
    setIsSuccess(false);
    const { id } = project;
    try {
      await updateVeryHiddenSheet(excelContext);
      if (window.selectedChart) {
        const chartData = JSON.parse(window.selectedChart);
        const { workSheet: workSheetName, chartName, uniqueId } = chartData;
        await postChart(id, {
          title,
          workSheetName,
          uniqueId,
          chartName,
          sharePointItemUrl
        });
        handleSuccess();
      } else {
        const data = await getExcelCellRangeOrChart(excelContext);
        const { range, sheet } = data;
        const { name: workSheetName, id: uniqueId } = sheet;
        const { address: selectedRange } = range;

        if (range.address) {
          await postCellRange(id, {
            workSheetName,
            uniqueId,
            selectedRange,
            sharePointItemUrl
          });
        }
        handleSuccess();
      }
    } catch (e) {
      handleError();
    }
  }, [project]);

  useEffect(() => {
    if (!projectId) {
      return;
    }
    const fetchProjectSettings = async () => {
      try {
        const response = await getProjectSettingsById(projectId);
        if (
          response.data &&
          response.data.ENABLE_DYNAMIC_INGESTION &&
          response.data.ENABLE_DYNAMIC_INGESTION.toString().toLowerCase() ===
            'false'
        ) {
          setEnableDynamicIngestion(false);
        }
      } catch (catchError) {
        console.error('Error while fetching project settings', catchError); // eslint-disable-line
      }
    };
    fetchProjectSettings();
  }, [projectId]);

  useEffect(() => {
    dispatch(setWorkbookThunk(window.Office.context.document));
    dispatch(setProjectThunk(sharePointItemUrl));
  }, [dispatch, sharePointItemUrl]);

  useEffect(() => {
    async function addChartEvents() {
      const data = await getExcelSheetName(excelContext);

      if (!initChartsForSheetName.current[data.sheet.name]) {
        await selectRange('A1:A1');
        await addChartEventListeners(excelContext);
        initChartsForSheetName.current[data.sheet.name] = true;
        setInitialLoading(false);
      }
    }
    if (excelContext && project) {
      // First load
      addChartEvents();
      // Possible sheet change
      window.Office.context.document.addHandlerAsync(
        window.Office.EventType.DocumentSelectionChanged,
        async () => {
          addChartEvents();
          window.selectedChart = null;
        }
      );
    }
  }, [excelContext, project]);

  useEffect(() => {
    if (projectConnected != null && !projectConnected) {
      setInitialLoading(false);
    }
  }, [projectConnected]);

  if (isLoadingFileSize) {
    return (
      <ContentLayout title={project && project.name}>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={100}
          animation="wave"
        />
      </ContentLayout>
    );
  }

  return (
    <ContentLayout title={project && project.name}>
      <FileSizeWarning
        fileSizeInBytes={fileSizeInBytes}
        hasErrorFileSize={hasErrorFileSize}
      >
        <>
          <Card
            title="Add a table or chart"
            tooltipLabel="Learn how to add a table or chart"
            tooltipLink="https://sites.google.com/pwc.com/junction-global/how-to-guides/upload-an-excel-chart-or-table"
          >
            {initialLoading ? (
              <Skeleton
                variant="rectangular"
                width="100%"
                height={272}
                animation="wave"
              />
            ) : (
              <Box>
                <Typography sx={{ mb: '1rem' }}>
                  Select a cell range or chart to add to Junction. Use this
                  option for static content like a table.
                </Typography>

                <Button
                  disabled={
                    isAdding || (projectConnected != null && !projectConnected)
                  }
                  variant="outlined"
                  onClick={handleAddStaticObjectClick}
                  sx={{
                    height: '2.375rem'
                  }}
                >
                  {isAdding ? (
                    <Loader
                      size={20}
                      color={isAdding ? 'inherit' : 'primary'}
                    />
                  ) : (
                    'Add selected item'
                  )}
                </Button>
                {isSuccess && (
                  <Box
                    sx={{
                      mt: '1rem'
                    }}
                  >
                    <InfoBox description="Object now available in Junction." />
                  </Box>
                )}
                {isError && <CopyErrorMessage />}
              </Box>
            )}
          </Card>
          {isWorksheetIngestionEnabled ? (
            <Box>
              <Card
                title="Add worksheet"
                tooltipLabel="Learn how to add a worksheet"
                tooltipLink="https://sites.google.com/pwc.com/junction-global/how-to-guides/upload-an-excel-chart-or-table"
                alternativeTitleColor={
                  enableDynamicIngestion ? undefined : '#6E6E6E'
                }
              >
                {initialLoading ? (
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={272}
                    animation="wave"
                  />
                ) : (
                  <Box>
                    <Typography sx={{ mb: '1rem' }}>
                      {enableDynamicIngestion ? (
                        <>
                          Embed this tab in Junction. Use this option for
                          interactive content like slicers.
                        </>
                      ) : (
                        <InfoBox
                          description="Adding worksheets has been disabled for this project."
                          isWarning
                        />
                      )}
                    </Typography>
                    <DynamicObject
                      excelContext={excelContext}
                      sharePointItemUrl={sharePointItemUrl}
                      projectId={projectId}
                      drive={drive}
                      fileName={title}
                      enableDynamicIngestion={enableDynamicIngestion}
                    />
                  </Box>
                )}
              </Card>
            </Box>
          ) : null}
        </>
      </FileSizeWarning>
    </ContentLayout>
  );
};
