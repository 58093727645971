import { handleChartActivated } from './handleChartActivated';

export const addOnActivatedEventHandlerToCharts = async (
  excelContext,
  charts,
  setSelectedChart,
  setStatusMessage
) => {
  if (!charts) {
    return;
  }
  try {
    charts.forEach(chart => {
      if (!chart || !chart.onActivated) {
        return;
      }
      chart.onActivated.add(event => {
        handleChartActivated(
          event,
          excelContext,
          charts,
          setSelectedChart,
          setStatusMessage
        );
      });
    });
    await excelContext.sync();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
