import React, { useState } from 'react';
import { Button, Select } from '@pwc/pwc-deals-ui';

const ItemTypeSelect = ({ onCancel, onSave, options }) => {
  const [selectedType, setSelectedType] = useState('');

  return (
    <div className="card" data-testid="chart-picker">
      <h3 className="card-title">Select Content Type</h3>
      <form
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
          onSave(selectedType);
        }}
      >
        <label htmlFor="content-type">Content Type</label>

        <Select
          id="content-type"
          onChange={event => {
            const { value } = event.target;
            setSelectedType(value);
          }}
          options={options}
          optionTestId="chart-option"
          placeholder="Please select"
          selectTestId="item-selector"
          value={selectedType}
        />
        <div className="card-actions">
          <Button
            type="submit"
            data-testid="push-button"
            disabled={!selectedType}
            className="button"
          >
            OK
          </Button>
          <Button
            type="button"
            data-testid="push-button-cancel"
            className="button button-link"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ItemTypeSelect;
