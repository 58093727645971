import { createSlice } from '@reduxjs/toolkit';
import { MasterHttpClient } from '../../common/services/apiClients';
import { getSharepointSite } from './sharepointService';

export const getProject = state => state.project;
export const getProjectData = state => state.project.data;
export const getProjectId = state => state.project.data.id;
export const getProjectLoading = state => state.project.isLoading;
export const getProjectProcessing = state => state.project.isProcessing;
export const getProjectError = state => state.project.isError;
export const getProjectErrorMessage = state => state.project.errorMessage;
export const getProjectConnected = state => state.project.connected;

export const initialState = {
  isLoading: true,
  isError: false,
  isProcessing: false,
  connected: null,
  data: null,
  errorMessage: null
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setProjectSucceed: (state, action) => ({
      ...state,
      isLoading: false,
      isProcessing: false,
      isError: false,
      connected: true,
      data: action.payload
    }),
    setProjectNotConnected: state => ({
      ...state,
      isLoading: false,
      isProcessing: false,
      isError: false,
      connected: false
    }),
    processingStart: state => ({
      ...state,
      isLoading: false,
      isProcessing: true,
      isError: false
    }),
    processingComplete: state => ({
      ...state,
      isLoading: false,
      isProcessing: false,
      isError: false
    })
  }
});

export const {
  setProjectSucceed,
  setProjectNotConnected,
  processingStart,
  processingComplete
} = projectSlice.actions;

export default projectSlice.reducer;

// eslint-disable-next-line consistent-return
export const setProjectThunk = fullPath => async dispatch => {
  if (!fullPath) {
    return;
  }

  const sharePointSite = getSharepointSite(fullPath);

  if (sharePointSite) {
    let matchingD3ProjectResponse;
    try {
      matchingD3ProjectResponse = await MasterHttpClient.get({
        relativePath: `projects/search`,
        params: { sharePointSiteUrl: sharePointSite }
      });
      const { data } = matchingD3ProjectResponse.data;

      dispatch(setProjectSucceed(data));
    } catch (error) {
      dispatch(setProjectNotConnected());
    }
  } else {
    dispatch(setProjectNotConnected());
  }
};
