import React, { useMemo, useEffect, useState } from 'react';

import { Switch, Route } from 'react-router';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import ContentPickerContainer from '../features/ingestion/ContentPickerContainer';
import PrivateRoute from '../common/components/Security/PrivateRoute';
import Login from '../common/components/Security/Login';
import authorizationService from '../common/services/security/authorizationService';
import RichTextContainer from '../features/rich-text/RichTextContainer';
import Header from '../common/components/layout/Header';
import Excel from '../common/components/Excel/Excel';
import { getMuiTheme } from '../common/utilities/getMuiTheme';
import { getProjectConnected } from '../features/project/project';

import ExcelApp from './ExcelApp';
import PowerPointApp from './PowerPointApp';
import '@pwc/pwc-deals-ui/build/styles/main.css';
import '../styles/main.scss';
import InfoBox from './components/InfoBox';

const App = ({ info }) => {
  const muiTheme = useMemo(() => createTheme(getMuiTheme()), []);
  const [showDisconnectedMessage, setShowDisconnectedMessage] = useState(false);
  const projectConnected = useSelector(getProjectConnected);

  useEffect(() => {
    if (projectConnected != null && !projectConnected) {
      setShowDisconnectedMessage(true);
    } else {
      setShowDisconnectedMessage(false);
    }
  }, [projectConnected]);

  return (
    <ThemeProvider theme={muiTheme}>
      <>
        <Header />
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              return <Login host={info && info.host} />;
            }}
          />

          {info.host === window.Office.HostType.Excel && (
            <>
              {showDisconnectedMessage ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    mt: '1rem',
                    ml: '1rem',
                    mr: '1rem'
                  }}
                >
                  <InfoBox
                    description="This file is not linked to a Junction project. To add data to Junction, the file must be saved in the Junction project's SharePoint 'Free Form Excel' folder."
                    isError
                  />
                </Box>
              ) : null}
              <Excel />
              <PrivateRoute
                exact
                path="/content"
                component={ContentPickerContainer}
                isAuthorized={authorizationService.hasValidToken}
              />
              <PrivateRoute
                exact
                path="/rich-text"
                component={RichTextContainer}
                isAuthorized={authorizationService.hasValidToken}
              />
              <PrivateRoute
                exact
                path="/excel"
                component={ExcelApp}
                isAuthorized={authorizationService.hasValidToken}
              />
            </>
          )}

          <PrivateRoute
            exact
            path="/power-point"
            component={PowerPointApp}
            isAuthorized={authorizationService.hasValidToken}
          />
        </Switch>
      </>
    </ThemeProvider>
  );
};

export default App;
