import { getLibraryItemDataForExcelAddin } from '../../../common/services/libraryItemsService';
import { getLongDescriptionWithOnlySpecifiedChildren } from './ckEditorHtmlHelper';

const getLibraryItem = async (projectId, libraryItemLink) => {
  if (!libraryItemLink) return null;

  const { libraryItemId } = libraryItemLink;
  try {
    const result = await getLibraryItemDataForExcelAddin(
      projectId,
      libraryItemId
    );
    const libraryItemDto = result.data;

    const { richTextData } = libraryItemDto.data;
    const { childrenData } = libraryItemDto;

    const childIds = childrenData.map(cd => cd.id);
    const correctedRichTextData = getLongDescriptionWithOnlySpecifiedChildren(
      richTextData,
      childIds
    );

    const libraryItemWithUpdatedRichText = {
      ...libraryItemDto,
      data: {
        richTextData: correctedRichTextData
      }
    };

    return libraryItemWithUpdatedRichText;
  } catch (e) {
    throw new Error(`Failed to get library item ${libraryItemId}`);
  }
};

export default getLibraryItem;
