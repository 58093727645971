import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { combineReducers } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

import loggedInUserSlice from '../features/loggedInUser/loggedInUserSlice';
import projectSlice from '../features/project/project';
import workbookSlice from '../features/workbook/workbook';

export const history = createBrowserHistory();

const reduxLoggerMiddleware = createLogger({
  level: 'info',
  collapsed: true,
  predicate: () =>
    process.env.REACT_APP_ENABLE_REDUX_FIREHOSE &&
    process.env.REACT_APP_ENABLE_REDUX_FIREHOSE === 'on'
});

export const rootReducer = combineReducers({
  project: projectSlice,
  workbook: workbookSlice,
  loggedInUser: loggedInUserSlice
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk, reduxLoggerMiddleware],
  devTools: true
});
