import React from 'react';
import './InlineMessage.scss';

const createMarkup = text => {
  return { __html: text };
};

const InlineMessage = ({ text, type = 'info' }) => {
  if (!text) {
    return '';
  }

  return (
    <div
      className={`inline-message inline-message--${type}`}
      data-testid="inline-message"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={createMarkup(text)}
    />
  );
};

export default InlineMessage;
