import { StructuredAnalysisHttpClient } from '../../../common/services/apiClients';

const createLinkLibraryItem = context => {
  const { exists, name, documentId, libraryItemId, projectId } = context;
  if (exists) return Promise.resolve(context);

  const path = `projects/${projectId}/StructuredAnalysisDocuments/${documentId}/libraryItems`;
  const postData = {
    libraryItemId,
    textKey: name
  };

  return StructuredAnalysisHttpClient.post({
    relativePath: path,
    data: postData
  })
    .then(() => context)
    .catch(e => {
      throw new Error(e.message);
    });
};

export default createLinkLibraryItem;
