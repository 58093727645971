import { useEffect, useState } from 'react';

export const useExcelContext = () => {
  const [excelContext, setExcelContext] = useState(null);

  useEffect(() => {
    if (window.Excel) {
      window.Excel.run(async context => {
        setExcelContext(context);
      });
    }
  }, []);

  return excelContext;
};
