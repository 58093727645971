import React from 'react';
import { useSelector } from 'react-redux';
import {
  getProjectLoading,
  getProjectError,
  getProjectErrorMessage,
  getProjectConnected,
  getProjectData
} from '../../../features/project/project';
import Loading from '../Loading/Loading';
import ProjectTitle from '../ProjectDetails/ProjectTitle';
import InlineMessage from '../InlineMessage/InlineMessage';
import platformName from '../../utilities/get.platform.name';

const ExcelProject = ({ excelContext, children }) => {
  const projectLoading = useSelector(getProjectLoading);
  const projectError = useSelector(getProjectError);
  const projectConnected = useSelector(getProjectConnected);
  const projectErrorMessage = useSelector(getProjectErrorMessage);
  const project = useSelector(getProjectData);

  if (projectLoading || !excelContext) {
    return <Loading />;
  }

  if (projectError) {
    return (
      <p className="status-message" data-testid="status-message">
        {projectErrorMessage}
      </p>
    );
  }

  if (!projectConnected) {
    return (
      <main>
        <InlineMessage
          text={`File not connected to a ${platformName} Project`}
          type="failure"
        />
      </main>
    );
  }

  return (
    <main>
      <ProjectTitle project={project} />
      {children}
    </main>
  );
};

export default ExcelProject;
