import userTokenService from './userTokenService';

const authenticationUrlBuilder = {
  build: async isAddInNewVersion => {
    const stateData = isAddInNewVersion
      ? `${window.location.origin}?ingestion=2.0`
      : window.location.origin;
    const state = btoa(stateData);
    const { data } = await userTokenService.getAuthenticationUrl(
      encodeURIComponent(window.location.origin),
      state
    );

    return data;
  }
};

export default authenticationUrlBuilder;
