const getAdjustmentNumberCell = context => {
  const {
    columnOffset,
    adjustmentNumberColumnIndex,
    row,
    worksheet,
    excelContext
  } = context;

  const cell = worksheet.getCell(
    row,
    adjustmentNumberColumnIndex + columnOffset
  );

  cell.load('values');
  return excelContext
    .sync()
    .catch(() => {
      throw new Error('Error fetching Adj. #');
    })
    .then(() => {
      const adjustmentNumber = cell.values[0][0];
      if (adjustmentNumber === '') {
        throw new Error('Please fill out the Adj. #');
      }
      return {
        ...context,
        adjustmentNumber
      };
    });
};

export default getAdjustmentNumberCell;
